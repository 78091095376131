import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
  getUserId,
} from "../../comman/function";
import API from "../../constants/API";
import { authHeader, getAlertToast, getAlert } from "../../comman/mainfunction";
import { UPDTAE_SUPPLIER_REQUEST_URL } from "../../comman/url";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../../comman/helperfunction";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const InviteSendModal = ({
  open,
  addToggleClose,

  requestListing,
  requestId,
  selected
}) => {
  const styleclasses = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const validationSchema = Yup.object({
    // supplierName: Yup.string()
    // .lowercase()
    // .required("Supplier Name Required"),
   // supplierName: Yup.string().required("Required"),
    supplierEmail: Yup.string()
      .required("Supplier Email Required")
      .lowercase()
      .email("Invalid email"),
      
    // .oneOf(
    //   [Yup.ref("supplierConfirmEmail"), ""],
    //   "supplier confirm email must needed"
    // ),
    supplierConfirmEmail: Yup.string()
      .lowercase()
      .email("Invalid email")
      // .required("Supplier Confirm Email Required")
      .oneOf(
        [Yup.ref("supplierEmail"), ""],
        "supplier email and confirm supplier email address field must be same"
      ),
    // .when("supplierEmail", (supplierEmail) => {
    //   if(supplierEmail.value.length !=0)
    //     return Yup.string().required("Must enter email address")
    // }),
  });

  const saveFormValues = async (values) => {
    console.log(selected)
    setLoading(true);
    const config = {
      method: "PUT",
      url: UPDTAE_SUPPLIER_REQUEST_URL,
      headers: authHeader(),
      data: {
        // ...values,
        // teamId: getTeamID(),
        // requestedBy: getUserId(),
        webString: enCryptFun(
          JSON.stringify({
            requestId:requestId,
            supplierName: selected.supplierCompanyName,
            supplierEmail: values.supplierEmail,         
            inviteToPortal: checked,
            supplierCode:selected.supplierCode,
            adminUrl:window.location.origin          
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status, message } = response.data;
      let l = deCryptFun(response.data);

      const { status, message } = JSON.parse(l);
      if (status === "Success") {
        // Swal.fire("Success:", message);
        // Swal.fire(getAlertToast("Success", "Request sent Successfully!"));
        if (values.supplierEmail !== "") {
          CustomMessage(
            "Invitation sent successfully",
            "success",
            enqueueSnackbar
          );
        } else {
          CustomMessage(
            "Supplier added successfully",
            "success",
            enqueueSnackbar
          ); //call toster
        }
      } else {
        CustomMessage(message, "error", enqueueSnackbar); //call toster
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      addToggleClose();
      requestListing()
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, values, errors, touched, setFieldError } =
    useFormik({
      initialValues: {        
        supplierEmail: "",
        supplierConfirmEmail: "",
      },
      validationSchema,
      onSubmit: (values) => {
        if (values.supplierEmail != "") {
          if (values.supplierConfirmEmail == "") {
            setFieldError(
              "supplierConfirmEmail",
              "Please confirm supplier Email"
            );
            return null;
          }
        }
        if (values.supplierConfirmEmail != "") {
          if (values.supplierEmail == "") {
            setFieldError("supplierEmail", "Supplier Email is required");
            return null;
          }
        }
        saveFormValues(values);
      },
    });

  const handleChange2 = (event) => {
    setChecked(event.target.checked);
  };

  const supplierEmailHandler = (e) => {
    setChecked(false);
    handleChange(e);
  };

  return (
    <>
      <BootstrapDialog
        // onClose={addToggleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={addToggleClose}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Send Invite 
        </BootstrapDialogTitle>
        <Box component={"form"} onSubmit={handleSubmit}>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Supplier Name
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.supplierName}
                onChange={handleChange}
                name="supplierName"
                placeholder="Enter Supplier Name"
              />
              <Typography style={{ color: "red" }}>
                {errors.supplierName && touched.supplierName
                  ? errors.supplierName
                  : ""}
              </Typography>
            </Grid> */}

            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Supplier Email
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.supplierEmail}
                onChange={(e) => supplierEmailHandler(e)}
                name="supplierEmail"
                placeholder="Enter Supplier Email"
              />
              <Typography style={{ color: "red" }}>
                {errors.supplierEmail && touched.supplierEmail
                  ? errors.supplierEmail
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Confirm Supplier Email
              </Typography>
              <CssTextField
                fullWidth
                value={values.supplierConfirmEmail}
                name="supplierConfirmEmail"
                id="custom-css-outlined-input"
                onChange={handleChange}
                placeholder="Confirm Supplier Email"
              />
              <Typography style={{ color: "red" }}>
                {errors.supplierConfirmEmail && touched.supplierConfirmEmail
                  ? errors.supplierConfirmEmail
                  : ""}
              </Typography>
            </Grid>
            {values.supplierEmail && (
              <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                <FormControlLabel
                  label="Invite to Portal"
                  control={
                    <Checkbox checked={checked} onChange={handleChange2} />
                  }
                />
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button
                onClick={addToggleClose}
                className={styleclasses.canceldialog}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className={styleclasses.submitdialog}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
};

export default InviteSendModal;
