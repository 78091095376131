import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {
  GET_NOTIFICATION_URL,
  SAVE_NOTIFICATION_DETAILS,
  UPDATE_NOTIFICATIONS,
} from "../../comman/url";
import { authHeader } from "../../comman/mainfunction";
import { deCryptFun, enCryptFun, getUserId } from "../../comman/function";
import API from "../../constants/API";
import Loader from "../../comman/Loader";
import { NoRecordFound } from "../NoRecordFound";
import { NOTIFY_COUNT } from "../../redux/action/ActionType";
import { useDispatch } from "react-redux";
import { INVOICEPREVIEW_ROUTE } from "../../constants/RoutePaths";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    padding: "10px 15px",
    borderRadius: "12px",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  correctbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#CCF1EA !important",
    color: "#0E9F85 !important",
    boxShadow: "none",
    minWidth: "50px !important",
  },
  infobutton: {
    margin: "0px 16px !important",
    minWidth: "50px !important",
    backgroundColor: "#4E4B6615 !important",
    color: "#A0A3BD !important",
    boxShadow: "none",
  },
  cancelbutton: {
    margin: "0px 16px !important",
    minWidth: "50px !important",
    backgroundColor: "#F14A4A15 !important",
    color: "#F14A4A !important",
    boxShadow: "none",
  },
  startICon: {
    marginRight: "0px !important",
    "& .MuiSvgIcon-root": {
      fontSize: "25px !important",
      paddingLeft: "2px",
    },
  },
  approvedbadge: {
    background: "#793ED608 !important",
    color: "#793ED6 !important",
    fontSize: "14px !important",
    textTransform: "none !important",
    padding: "4px 16px !important",
    "&:before": {
      background: "#793ED630 !important",
    },
  },
  tableContainerbg: {
    background: "white !important",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
    borderRadius: "12px !important",
  },
  sortByDate: {
    display: "none",
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",
    with: "100px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#4A9EF1",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    onDragOver,
    onDrop,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();

  const headCells = [
    {
      id: "Invoice No",
      numeric: true,
      disablePadding: false,
      label: "Invoice Number",
    },
    {
      id: "User Id",
      numeric: true,
      disablePadding: false,
      label: "Supplier Name",
    },
    {
      id: "Email Message",
      numeric: true,
      disablePadding: false,
      label: "Details",
    },

    // {
    //   id: "Status",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "Status",
    // },
    // {
    //   id: "Activate/Deactivate",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "Activate/Deactivate",
    // },
  ];

  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={`${headCell.id != "action" && "custom-border"}`}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              // disabled={true}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
            {/* {headCell.id == "validationstatus" && (
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  (Invoice/Supplier/PO)
                </Typography>
              )} */}
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,

  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
let completedList = [
  {
    invoiceno: "INV001",
    userid: "USERID001",
    message: "Hi,Recived it",
  },
  {
    invoiceno: "INV002",
    userid: "USERID002",
    message: "Hi,Recived it",
  },
  {
    invoiceno: "INV003",
    userid: "USERID003",
    message: "Hi,Recived it",
  },
];
export default function NotificationList() {
  const styleclasses = useStyles();
  let navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  console.log(selectedId,"selectedId")
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  // const [completedList, setCompletedList] = useState(clist);
  const [incompletedList, setInCompletedList] = useState([
    {
      invoiceno: "INV0011",
      userid: "USERID004",
      message: "Hi,Recived it",
    },
    {
      invoiceno: "INV0012",
      userid: "USERID005",
      message: "Hi,Recived it",
    },
    {
      invoiceno: "INV0013",
      userid: "USERID006",
      message: "Hi,Recived it",
    },
  ]);
  const [ilist, setillist] = useState();
  const dispatch = useDispatch();

  //   const handleRequestSort = (event, property) => {
  //     const isAsc = orderBy === property && order === "asc";
  //     setOrder(isAsc ? "desc" : "asc");
  //     setOrderBy(property);
  //   };
  console.log("ffgf");
  const [completList, setCompleteList] = useState([
    // {
    //   invoiceId: "INV0011",
    //   userid: "USERID004",
    //   payload: "Hi,Recived it",
    // },
    // {
    //   invoiceId: "INV0011",
    //   userid: "USERID004",
    //   payload: "Hi,Recived it",
    // },
  ]);
  const [notcompleteList, setNotCompleteList] = useState();
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);
  const getNotificationDetails = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: GET_NOTIFICATION_URL,

      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, messageCount, readedMessages, unreadedMessages } =
        JSON.parse(l);
      if (status === "Success") {
        setCompleteList(readedMessages);
        setNotCompleteList(unreadedMessages);
        setCount(messageCount);
        dispatch({
          type: NOTIFY_COUNT,
          payload: messageCount[0].totalCount,
        });
        console.log("set", messageCount, readedMessages, unreadedMessages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getNotificationDetails();
  }, []);

  function createData(name, calories, fat, carbs, protein) {
    return {
      name,
      calories,
      fat,
      carbs,
      protein,
    };
  }
  const rows = [
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Donut", 452, 25.0, 51, 4.9),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Gingerbread", 356, 16.0, 49, 3.9),

    createData("Oreo", 437, 18.0, 63, 4.0),
  ];
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const onDragStart = (ev, id) => {
    console.log("dragstart:", id);
    ev.dataTransfer.setData("id", id);
  };
  const onDragOver = (ev) => {
    ev.preventDefault();
    console.log("drAG");
  };
  const saveNotification = async (id, nid, uid) => {
    const keycongigure = {
      method: "POST",
      url: SAVE_NOTIFICATION_DETAILS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            notificationDetailsId: nid,
            userId: uid.toString(),
            tag: "Notify Approvar",
            payload: "Notify Approvar",
            invoiceId: id,
            isRead: true,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(keycongigure);
      console.log(response);
      getNotificationDetails();
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const updateNotifications = async (selectedId) => {
    console.log(selectedId,'selectedId');
     const keycongigure = {
       method: "PUT",
       url: UPDATE_NOTIFICATIONS,
       headers: authHeader(),
       data: {
         webString: enCryptFun(
           JSON.stringify({
             notificationDetailsIds: selectedId.map((id) => ({
               notificationDetailsId: id
             })),
           })
         ),
         flutterString: "",
       },
     };
     try {
       const response = await API(keycongigure);
       console.log(response);
       setSelectedId([]);  
       getNotificationDetails();
     } catch (error) {
       console.log("error", error);
     } finally {
     }
   };
 

  const updateNotificationsClick=()=>{
    updateNotifications(selectedId)
  }
  const handleCheckboxChange=(id)=>{
    const tempSelectedId = [...selectedId];
    if(tempSelectedId.includes(id)){
      tempSelectedId.splice(tempSelectedId.findIndex(v=> v=== id),1)
    }else{
      tempSelectedId.push(id)
    }
    setSelectedId(tempSelectedId)
  }
  const handleSelectAll=(e)=>{
    const {checked} = e.target;
    const  tempSelectedId = 
    checked ? 
    notcompleteList.map(c=>c.notificationDetailsId) : [];
    setSelectedId(tempSelectedId)
  }

  const onDrop = (ev) => {
    let id = ev.dataTransfer.getData("id");
    console.log("drop calling");
    let tasks1 = notcompleteList.find((task, i) => task.invoiceId == id);
    let nid = notcompleteList.find(
      (task, i) => task.invoiceId == id
    ).notificationDetailsId;
    let uid = notcompleteList.find((task, i) => task.invoiceId == id).userId;
    //   if (task.id == id) {
    //     console.log(task)

    //   }
    //   return task

    // });
    saveNotification(id, nid, uid);

    completedList.push(tasks1);
    completList.push(tasks1);
    // setCompleteList(tasks1);
    let t1 = notcompleteList;

    var lists = t1.findIndex((x) => x.userid == id);
    if (lists !== -1) {
      t1.splice(lists, 1);
    }
    setNotCompleteList(t1);
    setInCompletedList(t1);
    setillist(t1);
    // setInCompletedList(incompletedList);
    console.log("list", lists);
  };
  console.log("listed full", completedList);
  const handleClick = (event) => {
    const invoiceID = event.currentTarget.dataset.id;

    navigate(`${INVOICEPREVIEW_ROUTE}/${invoiceID}`, {
      state: { locationChange: true },
    });
  };
  return (
    <>
      <Grid container sx={{ zIndex: "0" }} spacing={2}>
        <Grid
          sx={{ display: "flex", flexGrow: 1 }}
          justifyContent="left"
          item
          xs={12}
          md={6}
        >
          <Typography
            align="left"
            sx={{
              fontWeight: "500",
              fontSize: "26px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Notifications
          </Typography>
          <br />
        </Grid>
        <Grid></Grid>
      </Grid>
      <Grid id="main_editor_preview" container sx={{ mb: 2 }}>
        <Grid item xs={12} md={12}  sx={{display:'flex',justifyContent:'space-between'}}>
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Not Completed
          </Typography>
          {selectedId.length > 0 ? (<Button
           variant="outlined"
           className={styleclasses.canceldialog}
           component="span"
           onClick={updateNotificationsClick}
           style={{borderRadius:'5px',background: 'transparent',fontWeight:'500',cursor: 'pointer'}}
          >
         Mark as completed
        </Button>):''}
        </Grid>
      </Grid>
      <Grid justify="center" alignItems="center">
        {/* <Grid item xs={12} md={12} style={{ padding: "8px" }}> */}
        <Paper sx={{ mb: 2, borderRadius: "16px" }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer
            // sx={{ display: "block", textOverflow: "ellipsis" }}
            className={styleclasses.tableContainerbg}
            sx={{
              display: "block", textOverflow: "ellipsis",
              // minHeight: "450px",
              maxHeight: "550px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Table
              sx={{ minWidth: 700 }}
              className="invoicetablewidth"
              align="left"
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              {/* <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                //   onRequestSort={handleRequestSort}
                rowCount={rows.length}
              /> */}
               <TableHead sx={{backgroundColor:'#F0F1F5'}}>
                <TableRow>
                {loading ? '': <TableCell className="custom-border" sx={{display:'flex !important',alignItems:'center',justifyContent:'center',fontWeight: '550 !important'}}>
                   <FormGroup
                 style={{ justifyContent: "center", marginLeft: "2px"}}
                 >
                 <FormControlLabel
                  control={
                     <Checkbox
                       onChange={handleSelectAll}
                       style={{ backgroundColor: "#f0f1f5" ,borderBottom:"none",cursor:"pointer",marginLeft: "-10px"}}
                       checked={ notcompleteList?.every(e=>selectedId.includes(e.notificationDetailsId))}
                       size="big"
                       sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                     />
                   }
                 />
                </FormGroup >
                  </TableCell>}
                  <TableCell className="custom-border" sx={{fontWeight: '550 !important',textAlign:'center'}}>
                    Invoice Number
                  </TableCell>
                  <TableCell className="custom-border" sx={{fontWeight: '550 !important',textAlign:'center'}}>
                    Supplier Name
                  </TableCell>
                  <TableCell className="custom-border" sx={{display:'flex',justifyContent:'center',alignItems:'center',fontWeight: '550 !important'}}>
                  Detail
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {loading && ( */}
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <Loader />
                    </TableCell>
                  </TableRow>
                )}

                {!notcompleteList && !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <NoRecordFound />
                    </TableCell>
                  </TableRow>
                )}
                {!loading &&
                  notcompleteList &&
                  notcompleteList.map((rec, i) => {
                    const {
                      invoiceId,
                      payload,
                      supplierName,
                      notificationDetailsId,
                    } = rec;
                    return (
                      <TableRow
                        align="center"
                        // hover
                        // draggable
                        data-id={invoiceId}
                        onDragStart={(e) => onDragStart(e, rec.invoiceId)}
                      >
                         <TableCell sx={{display:'flex',justifyContent:'center'}}>
                        <FormGroup
                      style={{ justifyContent: "center", marginLeft: "2px"}}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                          checked={selectedId.includes(notificationDetailsId)}
                          onChange={() =>
                            handleCheckboxChange(notificationDetailsId)
                          }
                            style={{ backgroundColor: "#f0f1f5" ,borderBottom:"none",cursor:"pointer",marginLeft: "-10px"}}
                            size="big"
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                          />
                        }
                      />
                    </FormGroup>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                          data-id={invoiceId}
                          onClick={(event) => handleClick(event)}
                        >
                          {invoiceId}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                          data-id={invoiceId}
                          onClick={(event) => handleClick(event)}
                        >
                          {supplierName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                          data-id={invoiceId}
                          onClick={(event) => handleClick(event)}
                        >
                          {payload}
                        </TableCell>
                        {/* <TableCell
                        align="center"
                        style={{ fontWeight: 450, fontSize: 14 }}
                      >
                        Pending
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 450, fontSize: 14 }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <IconButton
                            style={{ color: "#EC7C37" }}
                          >
                            <EditIcon />
                          </IconButton>
                                                   <AntSwitch
                            
                            inputProps={{ "aria-label": "ant design" }}
                          />
                        </Stack>
                      </TableCell> */}
                      </TableRow>
                    );
                  })}

                {/* )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid id="main_editor_preview" container sx={{ mb: 2 }}>
        <Grid item xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Completed
          </Typography>
        </Grid>
      </Grid>
      <Grid justify="center" alignItems="center">
        {/* <Grid item xs={12} md={12} style={{ padding: "8px" }}> */}
        <Paper sx={{ mb: 2, borderRadius: "16px" }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer
            // sx={{ display: "block", textOverflow: "ellipsis" }}
            sx={{
              display: "block", textOverflow: "ellipsis",
              // minHeight: "450px",
              maxHeight: "600px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            className={styleclasses.tableContainerbg}
          >
            <Table
              sx={{ minWidth: 700 }}
              className="invoicetablewidth"
              align="left"
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              onDragOver={(e) => onDragOver(e)}
              onDrop={(e) => onDrop(e, "complete")}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                //   onRequestSort={handleRequestSort}
                rowCount={rows.length}
                onDragOver={(e) => onDragOver(e)}
                onDrop={(e) => onDrop(e, "complete")}
              />
              {console.log("list chk", completedList)}
              <TableBody>
                {/* {loading && ( */}
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <Loader />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow
                  onDragOver={(e) => onDragOver(e)}
                  onDrop={(e) => onDrop(e, "complete")}
                >
                  {"  "}
                </TableRow>
                {!completList && !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <NoRecordFound />
                    </TableCell>
                  </TableRow>
                )}

                {!loading &&
                  completList &&
                  completList.map((rec, i) => {
                    const { invoiceId, userId,supplierName ,payload } = rec;
                    return (
                      <TableRow
                        key={invoiceId}
                        align="center"
                        hover
                        data-id={invoiceId}
                        onDragOver={(e) => onDragOver(e)}
                        onDrop={(e) => onDrop(e, "complete")}
                        onClick={(event) => handleClick(event)}
                      >
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                        >
                          {invoiceId}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                        >
                          {supplierName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                        >
                          {payload}
                        </TableCell>
                        {/* <TableCell
                        align="center"
                        style={{ fontWeight: 450, fontSize: 14 }}
                      >
                        Pending
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 450, fontSize: 14 }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <IconButton
                            style={{ color: "#EC7C37" }}
                          >
                            <EditIcon />
                          </IconButton>
                          <AntSwitch
                            inputProps={{ "aria-label": "ant design" }}
                          />
                        </Stack>

                      </TableCell> */}
                      </TableRow>
                    );
                  })}

                {/* )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
}
