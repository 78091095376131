import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import React from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import * as Yup from "yup";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const filterInitial = { email: "", firstName: "", lastName: "" };
const FilterTeam = ({ open, handleClose, filterparams, TeamfilterHandler }) => {
  // const {  } = props;
  const styleclasses = useStyles();
  console.log(filterparams, "after");
  const postData = () => {};
  const validationSchema = Yup.object({
    email: Yup.string().lowercase().email("Invalid email"),
  });
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: {
      ...filterInitial,
      ...filterparams,
    },
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (values) => postToHandler(values),
  });

  const { firstName, lastName, email } = values;

  const postToHandler = (values) => {
    TeamfilterHandler(values);
    handleClose();
  };

  const resetField = () => {
    setValues(filterInitial);
    TeamfilterHandler(filterInitial);
    handleClose();
  };

  return (
    <Dialog
      classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A0A3BD",
            outline: "1px solid #A0A3BD",
            borderRadius: "50px",
          },
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Filters
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Email
              </Typography>
              <CssTextField
                name="email"
                value={values.email}
                onChange={handleChange}
                fullWidth
                id="custom-css-outlined-input"
              />
              <Typography style={{ color: "red" }}>
                {errors.email && touched.email ? errors.email : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                First Name
              </Typography>
              <CssTextField
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                fullWidth
                id="custom-css-outlined-input"
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Last Name
              </Typography>
              <CssTextField
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                fullWidth
                id="custom-css-outlined-input"
              />
            </Grid>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button type="submit" className={styleclasses.submitdialog}>
              Submit
            </Button>
          </DialogActions>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FilterTeam;
