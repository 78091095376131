import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import {
  Box,
  Paper,
  Grid,
  Button,
  Stack,
  InputBase,
  IconButton,
  Typography,
} from "@mui/material";
import Apptomate from "../images/apptomate.png";
import {
  ArrowDropDownIcon,
  SearchIcon,
  FilterListRoundedIcon,
} from "@mui/icons-material/Search";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PagenationPages from "./PagenationPages";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import API from "../constants/API";
import { deCryptFun, enCryptFun, getTeamID } from "../comman/function";
import Swal from "sweetalert2";
import Myteamfilter from "./Modal/Myteamfilter";
import { GET_USERS, GET_TEAMDETAILS_BY_ID } from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import { FormControl, MenuItem, Select } from "@mui/material";
import { debounce } from "underscore";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { useSelector } from "react-redux";
import AddSubSupplier from "./Modal/AddSubSupplier";
import SubSupplierTable from "../Table/SubSupplierTable";
const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },
  tablerow: {
    background: "black !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  antfont: {
    fontSize: "18px !important",
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
  },
  autoapprovedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  advancedbutton: {
    textTransform: "none !important",
    boxShadow: "none",
    color: "#14142a50 !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  exportgrid: {
    top: "120px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
}));
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#A0A3BD",

    fontSize: "14px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const initialdata = {
  offset: 0,
  count: 10,
};

export default function AddSupplier(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = props;
  const styleclasses = useStyles();
  const [value, setValue] = React.useState("1");
  const [addmodalOpen, setaddmodalOpen] = useState(false);
  const [dataparams, setdataParams] = useState(initialdata);
  const [supplierList, setSupplierList] = useState([]);
  const [totalRecord, settotalRecord] = useState(0);
  const [supplierData, setsupplierData] = useState({});
  const [loading, setLoading] = useState(false);

  const addToggleClose = () => setaddmodalOpen((v) => !v);

  const TeamReducer = useSelector((s) => s.TeamReducer);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let GetSupplierList = async (filterparams) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: GET_USERS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            // userRole: "Team Member",
            ...filterparams,
            userRole: "Supplier",
            // email: filterparams.email?.replace("'", "\\'"),
            // firstName: filterparams.firstName?.replace("'", "\\'"),
            // lastName: filterparams.lastName?.replace("'", "\\'"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);

      let l = deCryptFun(response.data);

      const { status, data, count } = JSON.parse(l);
      // const { status, data, count } = response.data;
      if (status === "Success") {
        setSupplierList(
          data.map((item) => {
            return {
              ...item,
              position: item.position || "-",
            };
          })
        );
        settotalRecord(count);
        setLoading(false);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  const debounceList = useRef(
    debounce((dataparams) => GetSupplierList(dataparams))
  ).current;
  useEffect(() => {
    debounceList(dataparams);
  }, [dataparams]);
  const successCallBack = () => {
    addToggleClose();
    debounceList(dataparams);
  };
  const getTeamDetailsById = async () => {
    const config = {
      method: "GET",
      url: GET_TEAMDETAILS_BY_ID,
      headers: authHeader(),
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status, data: initialResponse = [] } = response.data;
      let l = deCryptFun(response.data);
      const { status, data: initialResponse = [] } = JSON.parse(l);
      if (status === "Success") {
        setsupplierData(initialResponse[0]);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // console.log("error data new", parsedErr.message);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
    }
  };
  useEffect(() => {
    getTeamDetailsById();
  }, []);
  const nextPage = () => {
    setdataParams((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previorsPage = () => {
    setdataParams((va) => ({ ...va, offset: va.offset - 1 }));
  };
  const rowHandle = (ve) => {
    setdataParams((v) => ({ ...v, count: ve.target.value, offset: 0 }));
  };
  const delaySearch = useRef(
    debounce((dataparams) => GetSupplierList(dataparams))
  ).current;
  useEffect(() => {
    delaySearch(dataparams);
  }, [dataparams]);
  
  const successCall = () => {
    delaySearch(dataparams);
  };
  return (
    <>
      <Grid sx={{ pt: 2 }} container spacing={2}>
        <Grid justifyContent="left" item xs={12} md={6}>
          <Typography align="left" sx={{ fontWeight: 500, fontSize: "34px" }}>
            Supplier
          </Typography>
        </Grid>
        <Grid sx={{ paddingTop: "0 !important" }} item xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            <img src={TeamReducer.companyLogo} className="companyLogo"></img>
          </Stack>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sx={{ pt: 3 }} container spacing={2}>
        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
            <TabContext value={value}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ bgcolor: "none" }}>
                  <Grid item xs={12} md={6} lg={6} sx={{ display: "flex" }}>
                    <Box sx={{ bgcolor: "none" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <AntTab
                          value="1"
                          label="Suppliers"
                          className={styleclasses.antfont}
                          style={{
                            fontWeight: 500,
                          }}
                        />
                      </TabList>
                    </Box>
                  </Grid>
                  <TabPanel value="1" sx={{ padding: "24px 0px 24px 0px" }}>
                    <Box sx={{ width: "100%" }}>
                      <Grid item xs={12} md={12}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ textAlign: "right", "margin-left": "auto" }}
                          className={styleclasses.exportgrid}
                        >
                          <Tooltip
                            title="Add User"
                            placement="top"
                            arrow
                            classes={{
                              arrow: styleclasses.arrow,
                              tooltip: styleclasses.tooltip,
                            }}
                          >
                            <Button
                              onClick={addToggleClose}
                              className={styleclasses.notifydownloadbtn}
                              variant="contained"
                            >
                              Add User
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid sx={{ mt: 1 }} container spacing={2}>
                          <Grid xs={12} md={12}>
                            <SubSupplierTable
                             successCall={successCall}
                              supplierList={supplierList}
                              loading={loading}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <PagenationPages
                        dataparams={dataparams}
                        rowHandle={rowHandle}
                        totalRecord={totalRecord}
                        previorsPage={previorsPage}
                        nextPage={nextPage}
                      />
                    </Box>
                  </TabPanel>
                </Box>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
        <AddSubSupplier
          addToggleClose={addToggleClose}
          open={addmodalOpen}
          initialData={{}}
          supplierData={supplierData}
          successCallBack={successCallBack}
        />
      </Grid>
    </>
  );
}
