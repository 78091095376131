import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReactApexChart from "react-apexcharts";
import InvoiceTable from "../Table/DashboardInvoiceTable";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Divider from "@mui/material/Divider";
import Uploadinvoice from "./Modal/Uploadinvoice";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Invoice from "./Invoice";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import { makeStyles } from "@mui/styles";
import { DASHBOARD, GET_USER_BY_ID } from "../comman/url";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import html2canvas from "html2canvas";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getFullYear,
  getRole,
  getTeamID,
  getUserId,
} from "../comman/function";
import API from "../constants/API";
import { authHeader } from "../comman/mainfunction";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { INVOICE_ROUTE } from "../constants/RoutePaths";
import Loader from "../comman/Loader";
import UploadInvoiceNew from "./Modal/UploadInvoiceNew";
import { useSelector } from "react-redux";
import {
  TIME_MMDDYYYY_REGEX,
  VALID_DATE_DDMMYYYY_REGEX,
  VALID_DATE_MMDDYYYY_REGEX,
} from "../comman/constants";
const useStyles = makeStyles((theme) => ({
  loginbox: {
    backgroundColor: "#fff",
    padding: "30px 50px",
    borderRadius: "16px",
    textAlign: "left",
    boxShadow: "3px 3px 20px #00000017",
  },
  loginbtn: {
    backgroundColor: "#ec7c37 !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    fontSize: "12px !important",
    "&:hover": {
      fontSize: "12px !important",
      color: "#ec7c37 !important",
      border: "1px solid #ec7c37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  downbn: {
    backgroundColor: "#ec7c37 !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    fontSize: "12px !important",
    border: "1px solid #ec7c37 !important",
    "&:hover": {
      color: "#ec7c37 !important",
      fontSize: "12px !important",
      border: "1px solid #ec7c37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "10px !important",
  backgroundColor: "white !important",
  boxShadow: "2px 2px 1px #0000001c",
  marginLeft: "25px !important",
  height: "37px",
  padding: "0px 0px",

  "&:hover": {
    backgroundColor: "white !important",
  },
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
    display: "flex",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "40px",
    [theme.breakpoints.up("sm")]: {
      width: "0ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const dateFormate = (date) => {
  return moment(date).utc().format("YYYY-MM-DD")
};
const dateFormateyear = (date) => {
  return moment(date).format("YYYY");
};
export default function Dashboard() {
  const styleclasses = useStyles();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  var year = new Date().getFullYear();
  var currentYearfirstaDate = new Date(year, 0, 1);
  let previousyrdate=new Date().setFullYear(new Date().getFullYear() - 1);
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [recentInvoice, setrecentInvoice] = useState([]);
  const [data, setdata] = useState([]);
  const [Months, setMonths] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [filterState, setfilterState] = useState({
    analysisDate: new Date(),
    fromDate: previousyrdate,
    toDate: new Date(),
  });

  const [amount, setamount] = useState(0);
  const [Pending, setPending] = useState(0);
  const [Approved, setApproved] = useState(0);
  const [ExceptionStatus, setExceptionStatus] = useState(0);
  const [AutoApproved, setAutoApproved] = useState(0);
  const [SpinnerToggle, setSpinnerToggle] = useState(false);
  const [value, setValue] = React.useState(new Date());
  const [inviteSuppliermodal, setInviteSuppliermodal] = React.useState(false);
  const createinvoiceopen = () => setInviteSuppliermodal((v) => !v);
  const [invoiceFilterChange, setInvoiceFilterChange] = useState("");
  const [invoiceFilterLoading, setInvoiceFilterLoading] = useState(false);
  const [analysisFilterChange, setAnalysisFilterChange] = useState("");
  const [analysisFilterLoading, setAnalysisFilterLoading] = useState(false);
  const TeamReducer = useSelector((s) => s.TeamReducer);
  const [toDate, setToDate] = useState("");

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  var baroptions = {
    chart: {
      type: "bar",
      height: 250,
      stacked: true,

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: { title: { show: false, formatter: () => "" } },
    },
    yaxis: {
      // title: false,

      labels: {
        formatter: function (value) {
          return (
            "$" +
            value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          );
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return "";
      },
    },
    plotOptions: {
      // bar: {
      //   horizontal: false,
      //   borderRadius: 10,
      //   columnWidth: "20%",
      // },
    },
    xaxis: {
      type: "text",
      categories: Months,
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };
  // console.log(getIsException(),"getIsException")
  // console.log("data",data);

  //analysis chart

  let analysisseries = [Approved, AutoApproved, Pending, ExceptionStatus];
  var analysisoptions = {
    colors: ["#793ed6", "#4a9ef1", "#f4b940", "#FF7519"],
    labels: [
      "Approved Invoices",
      "Auto Approved Invoices",
      "Pending Invoices",
      "Exception Invoices",
    ],

    tooltip: { enabled: false },
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            show: true,
            name: { show: true },
            value: { show: true, fontSize: "30px", fontWeight: "700" },
            total: { show: true, label: "Total Invoices" },
          },
        },
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 200,
    //       },
    //       legend: {
    //         position: "bottom",
    //       },
    //     },
    //   },
    // ],
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const getAmountValue = async () => {
    let amount;
    const configuser = {
      method: "GET",
      url: GET_USER_BY_ID,
      headers: authHeader(),
      params: {
        //userId: getUserId(),
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(configuser);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        const { approvalAmountTo } = data[0];

        setamount(approvalAmountTo);
      }
    } catch (error) {
      //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        // console.log("error data new",  v.message)
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  let GetDashBoard = async (filterValues) => {
    if (invoiceFilterChange !== "") {
      setInvoiceFilterLoading(true);
    } else if (analysisFilterChange !== "") {
      setAnalysisFilterLoading(true);
    } else {
      setSpinnerToggle(true);
    }

    const config = {
      method: "GET",
      url: DASHBOARD,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,

            senderEmail: getRole() === "Supplier" ? getEmail() : "",
            userId:
              getRole() === "Team Member" && amount !== -1 ? getUserId() : 0,
            ...filterValues,
            fromDate:dateFormate(filterValues.fromDate),
            toDate:dateFormate(filterValues.toDate),
            analysisDate:dateFormate(filterValues.analysisDate)
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);

      let l = deCryptFun(response.data);

      const { status, invoiceAnalysis, invoiceMonthVSAmount, recentInvoices } =
        JSON.parse(l);
      console.log("invoiceAnalysis", invoiceAnalysis);
      if (status === "Success") {
        const analysisValu = {
          Pending: 0,
          Approved: 0,
          Rejected: 0,
          Exception: 0,
        };

        invoiceAnalysis.forEach((val) => {
          analysisValu[val.status] = val.invoiceCount;
        });
      
        setrecentInvoice(recentInvoices);
        setPending(analysisValu.Pending);
        setApproved(analysisValu.Approved);
        setExceptionStatus(analysisValu.Exception);
        setAutoApproved(0); //by default

        // const PieData = invoiceStatusCount.map((i) => ({
        //   name: i.statusName,
        //   value: i.totalCount,
        // }));

        // setPieChartData(PieData);

        let Total = [];
        invoiceMonthVSAmount.forEach((val) => {
          let TotalAmounyt =
            (val.Approved || 0) + (val.Rejected || 0) + (val.Pending || 0);

          Total.push(parseFloat(TotalAmounyt).toFixed(1));
        });
        setdata([
          {
            name: "Total",
            data: Total,
          },
        ]);
        setMonths(invoiceMonthVSAmount.map((val) => val.Month));
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setSpinnerToggle(false);
      setInvoiceFilterLoading(false);
      setInvoiceFilterChange("");
      setAnalysisFilterLoading(false);
      setAnalysisFilterChange("");
    }
  };

  useEffect(() => {
    GetDashBoard(filterState);
  }, [
    filterState.analysisDate,
    filterState.fromDate,
    filterState.toDate,
    amount,
  ]);

  useEffect(() => {
    getAmountValue();
  }, []);

  const dateHandlechange = (name, value) => {
    // if(name =="fromDate"){
      

    if (
      name == "fromDate" &&
      dateFormate(new Date(value)) !=
        dateFormate(new Date().setFullYear(new Date().getFullYear() - 1))
    ) {
      if (
        name == "fromDate" &&
        dateFormate(new Date(value)) <
          dateFormate(new Date().setFullYear(new Date().getFullYear() - 1))
      ) {
        if (
          dateFormateyear(value) < "0999" ||
          dateFormateyear(value) > "0999"
        ) {
          return null;
        } else {
          CustomMessage("Invalid Date", "error", enqueueSnackbar);
          return null;
        }
      }
    }
    if (value == "Invalid Date") {
      return null;
    }
    
    if (name == "toDate" && dateFormate(new Date(value)) < dateFormate(new Date(filterState.fromDate))) {
      
      if (dateFormateyear(value) < "0999") {
        return null;
      } else {
        CustomMessage(
          "To Date is Invalid/Should be greater than From Date ",
          "error",
          enqueueSnackbar
        );
        return null;
      }
    }

    if (name == "analysisDate") {
      setAnalysisFilterChange("Invoice Analysis Filter is changed");
    } else {
      setInvoiceFilterChange("Invoice Filter is changed");
    }
    setfilterState((v) => ({ ...v, [name]: value }));
  };
 
  const generateDocument = async () => {
    await html2canvas(document.getElementById("main_editor_preview"), {
      useCORS: true,
      height: 1600,
      // width: 1200,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      var link = document.createElement("a");
      
      link.href = imgData;
      link.download = "filename";
      const pdf = new jsPDF({ orientation: "landscape" });
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
  
      pdf.addImage(imgData, "JPEG", 30, 0, width - 40, height + 20);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  };
  const invalidate = (e) => {
    // return null
    console.log(e);
  };
  const navigateTOinvoice = (e) => {
    navigation(INVOICE_ROUTE, {
      state: {
        date: moment(filterState.analysisDate).format("YYYY-MM-DD"),
        status: e.currentTarget.dataset.status,
      },
    });
  };
  const maxDateFunction = (e) => {
    if (filterState.toDate != null && filterState.toDate != "Invalid Date") {
      return new Date(filterState.toDate);
    } else {
      return new Date();
    }
  };

  const ptag = { margin: "7px", "font-weight": "bolder" };
  
  return (
    <>
      <Grid container sx={{ zIndex: "0" }} spacing={2}>
        <Grid
          sx={{ display: "flex", flexGrow: 1 }}
          justifyContent="left"
          item
          xs={12}
          md={6}
        >
          <Typography
            align="left"
            sx={{
              fontWeight: "500",
              fontSize: "26px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Dashboard
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            {/* <Button
              sx={{ backgroundColor: "#EC7C37", boxShadow: "none" }}
              className={styleclasses.loginbtn}
              variant="contained"
              onClick={createinvoiceopen}
            >
              New Invoice
            </Button> */}
            {!["Supplier", "Sub Supplier"].includes(getRole()) ? (
              <UploadInvoiceNew />
            ) : (
              <Button
                onClick={createinvoiceopen}
                className={styleclasses.downbn}
                variant="contained"
              >
                New Invoice
              </Button>
            )}
            <Button
              className={styleclasses.downbn}
              sx={{ backgroundColor: "#EC7C37", boxShadow: "none" }}
              variant="contained"
              disabled={SpinnerToggle}
              onClick={generateDocument}
            >
              Download
            </Button>
            <img src={TeamReducer.companyLogo} className="companyLogo"></img>
          </Stack>
        </Grid>
      </Grid>
      <Grid id="main_editor_preview" container sx={{ mb: 2 }}>
        <Grid item xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Invoices
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={12}
          sx={{
            p: 3,
            mt: 2,
            backgroundColor: "#fff",
            borderRadius: "16px",
            boxShadow: "2px 2px 9px #0000001c",
            zIndex: "0 !important",
            width: "100%",
          }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{ zIndex: "0", display: "flex" }}
            justifyContent="right"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack sx={{ display: "flex", flexDirection: "row" }} spacing={3}>
               
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={filterState.fromDate}
                   minDate={previousyrdate}
                  maxDate={maxDateFunction()}
                  allowSameDateSelection
                  onChange={(e) => dateHandlechange("fromDate", e)}
                  //  onChange={(e)=>{console.log(e)}}
                  onError={(e) => console.log(e)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{ width: "160px !important", mr: 2 }}
                    />
                  )}
                />

                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={filterState.toDate}
                  allowSameDateSelection
                  onChange={(e) => {
                    // setToDate(e);
                    dateHandlechange("toDate", e);
                  }}
                  minDate={new Date(filterState.fromDate)}
                  maxDate={new Date()}
                  onError={invalidate()}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        marginTop: "0px !important",
                        width: "160px !important",
                        marginRight: "16px !important",
                        height: "0.5375em",
                      }}
                    />
                  )}
                />
                {/* <MobileDatePicker
                    label="from"
                    value={new Date(filterState.fromDate)}
                 minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                    onChange={(e) => dateHandlechange("fromDate", e)}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{ width: "130px", mr: 2 }}
                      />
                    )}
                  /> */}
                {/* <MobileDatePicker
                    sx={{ mt: 0 }}
                    label="to"
                    minDate={new Date(filterState.fromDate)}
                    maxDate={new Date()}
                    value={new Date(filterState.toDate)}
                    onChange={(e) => dateHandlechange("toDate", e)}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{
                          marginTop: "0px !important",
                          width: "130px",
                          marginRight: "16px !important",
                          height: "0.5375em",
                        }}
                      />
                    )}
                  /> */}
              </Stack>
            </LocalizationProvider>
          </Grid>
          {SpinnerToggle || invoiceFilterLoading ? (
            <Box
              sx={{
                height: 250,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <ReactApexChart
              options={baroptions}
              series={data}
              type="bar"
              height={250}
            />
          )}
        </Grid>

        <Grid sx={{ mt: 5 }} container>
          <Grid item sx={{ pr: 3 }} xs={12} md={8}>
            <Typography
              sx={{ mb: 2, fontWeight: "600" }}
              align="left"
              variant="h5"
            >
              Recent
            </Typography>

            <InvoiceTable
              loading={SpinnerToggle}
              invoiceDataList={recentInvoice}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              align="left"
              sx={{ mb: 2, fontWeight: "600" }}
              variant="h5"
            >
              Invoice Analysis
            </Typography>
            <Box
              sx={{
                p: 3,
                backgroundColor: "#fff",
                borderRadius: "16px",
                boxShadow: "2px 2px 20px #00000012",
                height: "833px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack
                  sx={{ display: "flex", flexDirection: "row" }}
                  spacing={3}
                >
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    allowSameDateSelection
                    value={filterState.analysisDate}
                    onChange={(e) => dateHandlechange("analysisDate", e)}
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{ width: "160px !important" }}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              <Box sx={{ mt: 3 }}>
                {SpinnerToggle || analysisFilterLoading ? (
                  <Box
                    sx={{
                      height: 350,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </Box>
                ) : (
                  <ReactApexChart
                    options={analysisoptions}
                    series={analysisseries}
                    type="donut"
                    height={350}
                  />
                )}
              </Box>
              <Box>
                <List>
                  <ListItem
                    data-status="Approved"
                    onClick={navigateTOinvoice}
                    secondaryAction={
                      <ArrowForwardIosIcon
                        sx={{
                          border: "1px solid #ec7c37",
                          color: "#ec7c37",
                          width: "30px",
                          height: "30px",
                          padding: "4px",
                          borderRadius: "4px",
                        }}
                      />
                    }
                  >
                    <ListItemAvatar>
                      <CheckIcon
                        sx={{
                          backgroundColor: "#793ed621",
                          color: "#4a9ef1",
                          width: "35px",
                          height: "35px",
                          padding: "5px",
                          borderRadius: "4px",
                        }}
                      />
                    </ListItemAvatar>
                    {/* <ListItemText primary={`Approved invoice ${Approved}`} /> */}
                    Approved Invoices: <p style={ptag}>{Approved}</p>
                  </ListItem>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <ListItem
                    id="AutoApprovel"
                    data-status="Auto Approved"
                    onClick={navigateTOinvoice}
                    secondaryAction={
                      <ArrowForwardIosIcon
                        sx={{
                          border: "1px solid #ec7c37",
                          color: "#ec7c37",
                          width: "30px",
                          height: "30px",
                          padding: "4px",
                          borderRadius: "4px",
                        }}
                      />
                    }
                  >
                    <ListItemAvatar>
                      <AutorenewIcon
                        sx={{
                          background: "#4A9EF114",
                          color: "#4A9EF1",
                          width: "35px",
                          height: "35px",
                          padding: "5px",
                          borderRadius: "4px",
                        }}
                      />
                    </ListItemAvatar>
                    {/* <ListItemText
                      primary={`Autoapproved invoice ${AutoApproved}`}
                    /> */}
                    Auto Approved Invoices:
                    <p style={ptag}>{AutoApproved}</p>
                  </ListItem>
                  <Divider sx={{ mt: 2, mb: 2 }} />

                  <ListItem
                    data-status="Pending"
                    onClick={navigateTOinvoice}
                    secondaryAction={
                      <ArrowForwardIosIcon
                        sx={{
                          border: "1px solid #ec7c37",
                          color: "#ec7c37",
                          width: "30px",
                          height: "30px",
                          padding: "4px",
                          borderRadius: "4px",
                        }}
                      />
                    }
                  >
                    <ListItemAvatar>
                      <AccessTimeIcon
                        sx={{
                          backgroundColor: "#F4B94014 ",
                          color: "#F4B940",
                          width: "35px",
                          height: "35px",
                          padding: "5px",
                          borderRadius: "4px",
                        }}
                      />
                    </ListItemAvatar>
                    {/* <ListItemText primary={`Pending invoice `} /> */}
                    Pending Invoices:
                    <p style={ptag}>{Pending}</p>
                  </ListItem>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <ListItem
                    data-status="ExceptionStatus"
                    onClick={navigateTOinvoice}
                    secondaryAction={
                      <ArrowForwardIosIcon
                        sx={{
                          border: "1px solid #ec7c37",
                          color: "#ec7c37",
                          width: "30px",
                          height: "30px",
                          padding: "4px",
                          borderRadius: "4px",
                        }}
                      />
                    }
                  >
                    <ListItemAvatar>
                      <ReportGmailerrorredIcon
                        sx={{
                          backgroundColor: "#FF751914 ",
                          color: "#FF7519",
                          width: "35px",
                          height: "35px",
                          padding: "5px",
                          borderRadius: "4px",
                        }}
                      />
                    </ListItemAvatar>
                    Exception Invoices:
                    <p style={ptag}>{ExceptionStatus}</p>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} md={12}>
          <Uploadinvoice
            open={inviteSuppliermodal}
            successCall={() => {}}
            handleToggle={createinvoiceopen}
          />
        </Grid>
      </Grid>
    </>
  );
}
