import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { GET_SUPPLIER_LIST_URL } from "../../comman/url";
import API from "../../constants/API";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../../comman/function";
import { authHeader, getAlertToast } from "../../comman/mainfunction";
const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
  supplierList: {
    "& .MuiOutlinedInput-root.MuiAutocomplete-endAdornment": {
      right: "1px !important",
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));
let filterInitial = {
  status: "",
  fromDate: "",
  toDate: "",
  supplierName: "",
};
export default function MetrixInvoiceFilter(props) {
  const styleclasses = useStyles();
  const { openModal, closeModal, filterHandler, dataparams } = props;
  const [nameData, setNameData] = useState([]);
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const getSupplierList = async () => {
    const config = {
      method: "GET",
      url: GET_SUPPLIER_LIST_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            supplierId: getEmail(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let decrpt_response = deCryptFun(response.data);
      const { data } = JSON.parse(decrpt_response);

      setNameData(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        console.log("error data new", v.message);
        //Swal.fire(getAlert("Error", v.message));
      }
    }
  };
  useEffect(() => {
    getSupplierList();
  }, []);
  const postData = () => {};
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      ...filterInitial,
      ...dataparams,
    },
    enableReinitialize: true,
    onSubmit: (values) =>
      // postToHandler(values),
      onSubmitFilter(values),
  });
  console.log(dataparams, "dataparams");
  const {
    status = "",
    fromDate = " ",
    toDate = "",
    supplierName = "",
  } = values;
  const postToHandler = (values) => {
    filterHandler(values);

    console.log(values);
    closeModal();
  };

  const onSubmitFilter = (values) => {
    console.log("date values", values);
    if (values.fromDate != "" && values.fromDate != "Invalid date") {
      if (values.toDate === "" || values.toDate == "Invalid date") {
        setFieldError("toDate", "To date is required");
        return "";
      }
    }
    if (values.toDate != "" && values.toDate != "Invalid date") {
      if (values.fromDate === "" || values.fromDate === "Invalid date") {
        setFieldError("fromDate", "From date is required");
        return "";
      }
    }

    postToHandler(values);
  };

  const handleFromDate = (e) => {
    // if (e == "Invalid date") {
    //   setFromDateError(true);
    // } else {
    //   setFromDateError(false);
    // }
    console.log("e val", e);
    setValues((val) => ({
      ...val,
      fromDate:
        moment(e).format("YYYY-MM-DD") != "Invalid date"
          ? moment(e).format("YYYY-MM-DD")
          : "",
    }));
  };

  const handleToDate = (e) => {
    setValues((val) => ({
      ...val,
      toDate:
        moment(e).format("YYYY-MM-DD") != "Invalid date"
          ? moment(e).format("YYYY-MM-DD")
          : "",
    }));
  };

  const statusHandle = (e) => {
    if (
      e.target.value === "Beyond due date" ||
      e.target.value === "Potential Duplicate Invoices"
    ) {
      setValues((val) => ({
        ...val,
        fromDate: "",
        toDate: "",
        status: e.target.value,
      }));
    } else {
      setValues((val) => ({
        ...val,
        status: e.target.value,
      }));
    }
  };
  const resetField = () => {
    setValues({ ...filterInitial });
    // filterHandler(filterInitial);
    // resetForm();
    // closeModal();
  };
  console.log(values);
  var result = nameData.reduce((unique, o) => {
    if (!unique.some((obj) => obj.supplierName === o.supplierName)) {
      unique.push(o);
    }
    return unique;
  }, []);
  // console.log(result);

  const [edit, setEdit] = useState(false);
  const onChangeHandler = (event, newValue) => {
    setValues((val) => ({
      ...val,
      supplierName: newValue.supplierName,
    }));
    setEdit(true);
  };
  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openModal}
        classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A0A3BD",
              outline: "1px solid #A0A3BD",
              borderRadius: "50px",
            },
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeModal}
          >
            Filters
          </BootstrapDialogTitle>
          <DialogContent>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}> */}
            {/* <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Supplier Name
              </Typography> */}
            {/* <FormControl sx={{ width: "100%" }} variant="standard">
                <Autocomplete
                  disableClearable
                  name="supplierName"
                  className="supplierList"
                  // value={supplierName}
                  value={{ supplierName: values.supplierName || "" }}
                  onChange={(event, newValue) =>
                    // handleChange(newValue)
                    // setValues((val) => ({
                    //   ...val,
                    //   supplierName: newValue,
                    // }))
                    onChangeHandler(event, newValue)
                  }
                  options={result}
                  getOptionLabel={(option) => option.supplierName}
                  // disablePortal
                  id="combo-box-demo"
                  // options={
                  //   nameData && nameData.map((record, i) => record.supplierName)
                  // }
                  sx={{ width: "100%" ,borderRadius:"7px"}}
                  renderInput={(params) => <TextField {...params} sx={{borderRadius:"7px"}}/>}
                />
               
              </FormControl> */}
            {/* </Grid> */}
            <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Invoice Status
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <NativeSelect
                  style={{ fontFamily: "Montserrat" }}
                  id="demo-customized-select-native"
                  name="status"
                  value={status}
                  onChange={statusHandle}
                  input={<BootstrapInput />}
                >
                  {/* <option aria-label="None" value="" /> */}
                  <option value={""}>All</option>
                  {[
                    "Pending",
                    // "All",
                    "Approved",
                    "Initializing",
                    "Rejected",
                    "Reprocessing",
                    "Beyond due date",
                    "Potential Duplicate Invoices",
                    "Within 3% of Approval Limit",
                    "Request Information",
                    "Supplier Maintenance",
                    "Specialist Exception",
                    "Reject Invoice",
                  ].map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            {!["Beyond due date", "Potential Duplicate Invoices"].includes(
              values.status
            ) && (
              <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  From
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row" }}
                    spacing={3}
                  >
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      name="fromDate"
                      // maxDate={new Date()}
                      // allowSameDateSelection
                      value={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      onError={(e) => {
                        if (
                          e == "invalidDate" ||
                          e == "minDate" ||
                          e == "maxDate"
                        ) {
                          setFromDateError(true);
                        } else {
                          setFromDateError(false);
                        }
                      }}
                      maxDate={
                        values.toDate && values.toDate != "Invalid date"
                          ? new Date(values.toDate)
                          : new Date()
                      }
                      // error={fromDateError}
                      // helperText="Invalid Date"
                      renderInput={(params) => (
                        <TextField
                          onFocus={(e) => (e.target.placeholder = "MM/DD/YYYY")}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "From date",
                            // placeholder: "MM/DD/YYYY",
                          }}
                          error={errors.fromDate}
                          helperText={
                            // values.fromDate != "" && fromDateError
                            //   ? "Invalid Date"
                            //   : ""
                            errors.fromDate ? errors.fromDate : ""
                          }
                          sx={{ width: "100% !important" }}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            )}
            {!["Beyond due date", "Potential Duplicate Invoices"].includes(
              values.status
            ) && (
              <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  To
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row" }}
                    spacing={3}
                  >
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      name="toDate"
                      minDate={
                        values.fromDate && values.fromDate != "Invalid date"
                          ? new Date(values.fromDate)
                          : ""
                      }
                      allowSameDateSelection
                      value={toDate ? new Date(toDate) : null}
                      maxDate={new Date()}
                      onChange={handleToDate}
                      onError={(e) => {
                        if (
                          (e == "invalidDate" ||
                            e == "minDate" ||
                            e == "maxDate") &&
                          values.toDate != ""
                        ) {
                          setToDateError(true);
                        } else {
                          setToDateError(false);
                        }
                      }}
                      // error={toDateError}
                      renderInput={(params) => (
                        <TextField
                          onFocus={(e) => (e.target.placeholder = "MM/DD/YYYY")}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "To date",
                          }}
                          error={errors.toDate}
                          // helperText={toDateError ? "Invalid Date" : ""}
                          helperText={errors.toDate ? errors.toDate : ""}
                          sx={{ width: "100% !important" }}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            )}
          </DialogContent>
          <DialogActions style={{ padding: "8px 16px" }}>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button type="submit" className={styleclasses.submitdialog}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
