import * as types from "../action/ActionType";
export default function Requiredinvoicelineitem(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case types.REQUIRED_INVOICE_LINE_ITEM:
      return (state = payload);
    default:
      return state;
  }
}
