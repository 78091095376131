import * as types from "../action/ActionType";
export default function EditReducer(state = false, action) {
  const { type, payload } = action;
  switch (type) {
    case types.TURN_FLAG:
      return (state = payload);
    default:
      return state;
  }
}
