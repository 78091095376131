import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
  Stack,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/system";
import Swal from "sweetalert2";
import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import Scanner from "../Scanner_old";
import {
  GET_SUPPLIER_LIST_URL,
  PRE_SIGNED_URL,
  REUPLOAD_URL,
  USER_EMAIL_LIST_URL,
} from "../../comman/url";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
} from "../../comman/function";
import API from "../../constants/API";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import { useParams } from "react-router-dom";
import { INVOICE_ROUTE } from "../../constants/RoutePaths";
import { CustomMessage } from "../../comman/helperfunction";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useTheme } from "@mui/material/styles";

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];
const FileExtensions = {
  "application/pdf": "pdf",
  "application/PDF": "PDF",
  "image/png": "png",
  "image/PNG": "PNG",
  "image/jpeg": "jpeg",
  "image/JPEG": "JPEG",
  "image/jpg": "jpg",
  "image/JPG": "JPG",
  "image/tiff": "tiff",
  "image/TIFF": "TIFF",
};

const useStyles = makeStyles((theme) => ({
  dashboardcancelbtn: {
    display: "flex !important",
    justifyContent: "center !important",
  },
  BootstrapDialog: {
    width: 650,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",
    with: "100px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  uploadbun: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    width: "100px",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },

  nativedrop: {
    padding: "16.5px 14px",
  },
  dialogins: {
    fontSize: "14px",
    marginTop: "10px",
  },
  dialogCustomizedWidth: {
    maxWidth: "46%",
    overflow: "hidden",
    overflowY: "auto",
    width: "unset",
  },
  Uploadfiledrop: {
    position: "absolute",
    opacity: "0",
    top: "73px",
    left: "40px",
    height: "calc(29% + 4px)",
    width: "calc(93% + 5px)",
    outline: "none",
    textAlignLast: "center",
  },
  divider: {
    width: "700px",
    margin: "auto",
  },
}));

const Input = styled("input")({
  display: "none",
});
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

// const theme = useTheme();
// const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

const BUCKET_NAME = "hacov-inbox-ezcloud";
var folderName = "dashboard_uploads";
var uploadAPICall = null;

const Uploadinvoice = ({ open, handleToggle, successCall, theme }) => {
  const styleclasses = useStyles();
  const handleClose = () => {
    handleToggle();
    // successCall("cancel");
    setUploadOrScan(null);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [uploadOrScan, setUploadOrScan] = useState(null);

  const handleUploadOrScanCancel = () => {
    setSelectedFile();
    setUploadOrScan(null);
  };
  const handleSetUpload = () => setUploadOrScan("upload");
  const handleSetScan = () => setUploadOrScan("scan");
  let { invoiceID } = useParams();
  const [emailList, setEmailList] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const [cancelFlag, setCancelFlag] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [sourceType, setType] = useState();
  const constructFileName = (emailId) => {
    let uniqueKey = `${Date.now()}`;

    let extension = FileExtensions[sourceType] || "pdf" || "PDF";

    let fileName = `${uniqueKey}.${extension}`;

    // Upload the file to S3
    let documentName = folderName + "/" + fileName;

    if (emailId) {
      // Mail from folder isolation
      let mail_from_username = emailId.split("@")[0];
      let mail_from_fulldomain = emailId.split("@")[1];
      let mail_from_folder = mail_from_fulldomain.split(".")[0];
      folderName = `${mail_from_folder}/${mail_from_username}_${mail_from_folder}`;
      documentName = `${mail_from_folder}/${mail_from_username}_${mail_from_folder}/${fileName}`;
    }
    return { documentName, folderName, fileName };
  };

  const uploadInvoice = (e) => {
    let file = e.target.files[0];
    if (isSubmitting) {
      return;
    }
    if (file) {
      const filesize = Math.round(file.size / 1024);
      const { type } = file;

      setType(type);
      if (
        type === "image/png" || type === "image/PNG" ||
        type === "image/jpeg" || type === "image/JPEG" ||
        type === "image/jpg" || type === "image/JPG"
      ) {
        if (filesize >= 10367) {
          CustomMessage(
            "File size exceeds. It should follow the below criteria, it must not exceed 10MB",
            "error",
            enqueueSnackbar
          );

          return;
        }
      }
      if (type === "image/tiff" || "image/TIFF") {
        if (filesize >= 518350) {
          CustomMessage(
            "File size exceeds. It should follow the below criteria, it must not exceed 500MB",
            "error",
            enqueueSnackbar
          );

          return;
        }
      }
      if (type === "application/pdf" || type === "application/PDF") {
        if (filesize >= 30720) {
          CustomMessage(
            "File size exceeds. It should follow the below criteria, it must not exceed 30MB",

            "error",
            enqueueSnackbar
          );

          return;
        }
      }

      if (
        type === "application/pdf" || type === "application/PDF"||
        type === "image/png" || type === "image/PNG" ||
        type === "image/jpeg" || type === "image/JPEG" ||
        type === "image/jpg" || type === "image/JPG" ||
        type === "image/tiff" || type === "image/TIFF"
      ) {
        setSelectedFile(e.target.files[0]);
      } else {
        CustomMessage(
          "Please upload PDF,PNG,JPEG,TIFF file format only.",

          "error",
          enqueueSnackbar
        );
      }
    }
  };

  const UploadInvoiceSubmit = async (values) => {
    if (cancelFlag) {
      setCancelFlag(false);
      return;
    }
    if (!selectedFile) {
      CustomMessage(
        "Please upload file!",

        "error",
        enqueueSnackbar
      );
    } else {
      CustomMessage(
        "Uploading...",

        "success",
        enqueueSnackbar
      );

      setIsSubmitting(true);
      let s3FileUrl = "";
      let emailId = invoiceID ? "" : getEmail();
      //  getRole() !== "Supplier"
      // ? values.email
      // : getEmail();

      // Getting pre signed url
      const { documentName, folderName, fileName } = constructFileName(emailId);
      try {
        const config = {
          method: "POST",
          url: PRE_SIGNED_URL,
          headers: authHeader(),
          data: {
            // name: documentName,
            // type: sourceType,
            webString: enCryptFun(
              JSON.stringify({
                name: documentName,
                type: sourceType,
              })
            ),
            flutterString: "",
          },
        };

        const response = await API(config);
        //const { status, url } = response.data;
        let l = deCryptFun(response.data);
        const { status, url } = JSON.parse(l);
        if (status === "Success") {
          uploadAPICall = axios.CancelToken.source();

          let options = {
            headers: {
              "Content-Type": sourceType,
            },
            onUploadProgress: function (progressEvent) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadingPercentage(percentCompleted);
            },
            cancelToken: uploadAPICall.token,
          };

          await axios.put(url, selectedFile, options);

          s3FileUrl =
            "https://" + BUCKET_NAME + ".s3.amazonaws.com/" + documentName;
        }
      } catch (error) {
        console.log(error);
        console.log(JSON.stringify(error?.response));
        alert("Error uploading file");
        setIsSubmitting(false);
        setUploadingPercentage(0);
        setSubmitting(false); // formik
        return;
      }
      if (!s3FileUrl) {
        setIsSubmitting(false);
        return;
      }
      // making api call to lambda
      console.log("supplier name on upload email 11111", getSenderEmail());
      try {
        const option = {
          method: "POST",
          url: REUPLOAD_URL,
          params: {
            action: !invoiceID ? "Insert" : "Update",
            uploadBy: ["Supplier", "Sub Supplier"].includes(getRole()) ? "Supplier" : "Customer",
            invoiceId: invoiceID,
            s3FileUrl: s3FileUrl,
            fileType: FileExtensions[sourceType],
            emailId: getRole() !== "Sub Supplier"?emailId :getSenderEmail(),
            //emailId:emailId,
            documentName: documentName,
            fileName: fileName,
            folderName: folderName,
           // supplierName: values.email,
            supplierName: "",
            invoiceSource: ["Supplier", "Sub Supplier"].includes(getRole())? "Supplier Portal" : "Web",
            // toEmailId: invoiceID
            //   ? ""
            //   : getRole() !== "Supplier"
            //   ? ""
            //   : values.email,
            toEmailId: invoiceID
              ? ""
              : !["Supplier", "Sub Supplier"].includes(getRole())
              ? getSenderEmail()
              : values.email,
            adminUrl:window.location.origin
          },
          headers: { ...authHeader(), "Content-Type": "application/json" },
        
        };
        let response = await API(option);
        const { success, errorMsg = "" } = response.data;
        if (success) {
          CustomMessage(
            "uploaded successfully",

            "success",
            enqueueSnackbar
          );
          setSelectedFile();
          setValues((va) => ({ ...va, email: "" }));

          handleClose();
          successCall("");
        } else {
          CustomMessage(
            errorMsg,

            "error",
            enqueueSnackbar
          );
        }
      } catch (error) {
        CustomMessage(
          "Error",

          "error",
          enqueueSnackbar
        );
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().when([], {
      is: () => !invoiceID && getRole() === "Supplier",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
  });
  const {
    handleSubmit,
    handleChange,
    setSubmitting,
    values,
    handleBlur,
    errors,
    setValues,
    touched,
  } = useFormik({
    initialValues: { email: "" },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => UploadInvoiceSubmit(values),
  });
  console.log(errors, values, !invoiceID && getRole() === "Supplier");
  const getEmailList = async () => {
    const config = {
      method: "GET",
      url:
      ["Supplier", "Sub Supplier"].includes(getRole()) ? USER_EMAIL_LIST_URL : GET_SUPPLIER_LIST_URL,
      headers: authHeader(),
      params:
      ["Supplier", "Sub Supplier"].includes(getRole())
          ? {
              webString: enCryptFun(
                JSON.stringify({
                  entityType:
                  ["Supplier", "Sub Supplier"].includes(getRole()) ?  "Customer":"Supplier",
                  offset: 0,
                  count: 1000,
                  teamId: getTeamID(),
                })
              ),
              flutterString: "",
            }
          : {
              webString: enCryptFun(
                JSON.stringify({
                  //supplierId: getEmail(),
                  teamId: getRole() != "Supplier" ? getTeamID() : 0,
                })
              ),
              flutterString: "",
            },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { data } = JSON.parse(l);

      setEmailList(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        console.log("error data new", v.message);
        //Swal.fire(getAlert("Error", v.message));
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  useEffect(() => {
    getEmailList();
  }, []);
  const [cancelModalflag, setcancelModalflag] = useState(false);

  const onClickSubmittingAPICancel = () => {
    setcancelModalflag(true);
  };

  const cancleToggle = () => {
    setcancelModalflag(false);
  };
  const cancelInvoice = () => {
    if (uploadAPICall) {
      uploadAPICall.cancel("Upload cancelled");
      setUploadingPercentage(0);
      setCancelFlag(true); // formik wierd  - retries upload on cancelled api. so setting a flag and returning submit when true
      setIsSubmitting(false); // usestate
      setSubmitting(false); // formik
      setcancelModalflag(false);
    }
  };

  const handleFileChange = async (e) => {
    let file = e.target.files[0];
    if (isSubmitting) {
      return;
    }
    if (file) {
      const filesize = Math.round(file.size / 1024);
      const { type } = file;

      setType(type);
      if (
        type === "image/png" || type === "image/PNG" ||
        type === "image/jpeg" || type === "image/JPEG" ||
        type === "image/jpg" ||  type === "image/JPG"
      ) {
        if (filesize >= 10367) {
          CustomMessage(
            "File size exceeds. It should follow the below criteria, it must not exceed 10MB",

            "error",
            enqueueSnackbar
          );

          return;
        }
      }
      if (type === "image/tiff" || "image/TIFF" ) {
        if (filesize >= 518350) {
          CustomMessage(
            "File size exceeds. It should follow the below criteria, it must not exceed 500MB",

            "error",
            enqueueSnackbar
          );

          return;
        }
      }
      if (type === "application/pdf" ||"application/PDF" ) {
        if (filesize >= 30720) {
          // Swal.fire(
          //   getAlert(
          //     "error",
          //     "File size exceeds. It should follow the below criteria, it must not exceed 30MB"
          //   )
          // );
          CustomMessage(
            "File size exceeds. It should follow the below criteria, it must not exceed 30MB",
            "error",
            enqueueSnackbar
          ); //call toster

          return;
        }
      }

      if (
        type === "application/pdf" || type === "application/PDF" ||
        type === "image/png" || type === "image/PNG" ||
        type === "image/jpeg" || type === "image/JPEG" ||
        type === "image/jpg" || type === "image/JPG" ||
        type === "image/tiff" || type === "image/TIFF"
      ) {
        setSelectedFile(e.target.files[0]);
      } else {
        // Swal.fire(
        //   getAlert("error", "Please upload PDF,PNG,JPEG,TIFF file format only.")
        // );
        CustomMessage(
          "Please upload PDF,PNG,JPEG,TIFF file format only.",
          "error",
          enqueueSnackbar
        ); //call toster
      }
    }
  };
  const handleBlobChange = (blob) => {
    setSelectedFile(blob);
  };
  let name1 = "No file Selected!";

  if (selectedFile) {
    const { name } = selectedFile;
    name1 = name;
  }

  const cancleInvoice = () => {
    setSelectedFile();
    name1 = "";
    setValues((va) => ({ ...va, email: "" }));
    handleClose();
  };
  const onSupplierChange = (e) => {
    if (e.target.value !== "") {
      handleChange(e);
    }
  };
  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
        fullWidth
        maxWidth="md"
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            maxWidth: "80%",
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              padding: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A0A3BD",
              outline: "1px solid #A0A3BD",
              borderRadius: "50px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          // onClose={handleClose}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Upload Invoice
        </BootstrapDialogTitle>
        <Divider className={styleclasses.divider} />
        <form onSubmit={handleSubmit}>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              {!(uploadOrScan == "scan") && (
                <>
                  <Box
                    sx={{
                      textAlign: "center",
                      p: 5,
                      border: "1px dotted #ec7c37",
                      borderWidth: "4px",
                      height: "13rem",
                      borderRadius: "8px",
                      flexDirection: "column",
                      marginTop: "10px",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                    }}
                  >
                    {" "}
                    <div>
                      <Typography
                        gutterBottom
                        sx={{ fontWeight: 600, color: "#4E4B66" }}
                      >
                        Drag & drop a file here to upload{" "}
                      </Typography>
                    </div>
                    <div>
                      <button
                        style={{
                          backgroundColor: "#fff",
                          border: "none",
                        }}
                        sx={{
                          position: "fixed",
                          bottom: 16,
                          right: 16,
                          overflow: "hidden",
                        }}
                        className="Upload-btn"
                      >
                        <input
                          type="file"
                          name="myfile"
                          disabled={isSubmitting}
                          className={styleclasses.Uploadfiledrop}
                          onChange={handleFileChange}
                        />
                      </button>
                    </div>
                    <div>
                      <Typography
                        gutterBottom
                        sx={{ fontWeight: 600, color: "#ec7c37" }}
                      >
                        or
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: "75%",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-evenly",
                      }}
                    >
                      {!uploadOrScan && (
                        <>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <label htmlFor="contained-button-file">
                              <Input
                                onChange={uploadInvoice}
                                id="contained-button-file"
                                multiple
                                disabled={isSubmitting}
                                type="file"
                              />
                              <Button
                                variant="outlined"
                                className={styleclasses.canceldialog}
                                component="span"
                              >
                                Upload
                              </Button>
                            </label>
                          </Stack>

                          {!["Supplier", "Sub Supplier"].includes(getRole()) && (
                            <label
                              style={{ margin: "6px" }}
                              htmlFor="contained-button-file"
                            >
                              <Button
                                variant="contained"
                                disabled={isSubmitting}
                                onClick={handleSetScan}
                                className={styleclasses.submitdialog}
                                component="span"
                              >
                                Scan
                              </Button>
                            </label>
                          )}
                        </>
                      )}
                    </div>
                  </Box>
                </>
              )}
              <div className="upload-list">
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                  }}
                  className="list"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="paper-clip"
                    width="1.5em"
                    height="1.5em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                  </svg>
                  <p style={{ "margin-left": "5px" }}>{name1}</p>
                </div>
              </div>
              {!isSubmitting && uploadOrScan === "scan" && (
                <>
                  <Scanner handleBlobChange={handleBlobChange} />
                </>
              )}
              {isSubmitting && (
                <div style={{ textAlign: "center" }}>
                  <h3>Uploading...</h3>
                  <h5>{uploadingPercentage} % </h5>
                  {/* <p onClick={onClickSubmittingAPICancel}>Cancel</p> */}
                </div>
              )}

              <Box sx={{ mt: 1, mb: 1, color: "gray" }}>
                {!isSubmitting && uploadOrScan && (
                  <Typography className={styleclasses.dashboardcancelbtn}>
                    <Button
                      type="button"
                      onClick={handleUploadOrScanCancel}
                      className={styleclasses.canceldialog}
                    >
                      Cancel
                    </Button>
                  </Typography>
                )}
               <Typography className={styleclasses.dialogins}>
                  Invoice files must meet the following:
                </Typography>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    {" "}
                    PDF, it must not exceed 30MB{" "}
                    {/* <span sx={{ color: "#ec7c37 !important" }}>*</span> */}
                  </li>
                  <li>
                    {" "}
                    PNG or JPG, not to exceed 10MB
                    {/* <span sx={{ color: "#ec7c37" }}>*</span> */}
                  </li>
                  <li>
                    {" "}
                    TIFF, not to exceed 500MB
                    {/* <span sx={{ color: "#ec7c37" }}>*</span> */}
                  </li>
                </ul>
                {/* <Typography className={styleclasses.dialogins}>
                  If PDF, it must not exceed 30MB{" "}
                  <span sx={{ color: "#ec7c37 !important" }}>*</span>
                </Typography>
                <Typography className={styleclasses.dialogins}>
                  If PNG/JPG, it must not exceed 10MB{" "}
                  <span sx={{ color: "#ec7c37" }}>*</span>
                </Typography>
                <Typography className={styleclasses.dialogins}>
                  If TIFF, it must not exceed 500MB{" "}
                  <span sx={{ color: "#ec7c37" }}>*</span>
                </Typography> */}
              </Box>

              {invoiceID ? (
                ""
              ) :(
              <>
                 <Typography
                    gutterBottom
                    sx={{ fontWeight: 600, color: "#4E4B66" }}
                  >
                    {["Supplier", "Sub Supplier"].includes(getRole())
                      ? "Select the Subscriber"
                      : "Select the Supplier"}
                  </Typography>

                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    invalid={errors.email && touched.email ? true : false}
                    onBlur={handleBlur}
                    onChange={onSupplierChange}
                    name="email"
                    // value={values.email !== "" && values.email}
                    value={values.email || null}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {emailList.length === 0 && (
                      <MenuItem value="">No Record Found</MenuItem>
                    )}
                     {["Supplier", "Sub Supplier"].includes(getRole())
                      ? emailList &&
                        emailList.map((record, index) => {
                          return (
                            <MenuItem
                              key={`listing_${index}`}
                              value={record.email}
                            >
                              {record.companyName}
                            </MenuItem>
                          );
                        })
                      : emailList &&
                        emailList.map((record, index) => {
                          return (
                            <MenuItem
                              key={`listing_${index}`}
                              value={record.supplierName}
                            >
                              {record.supplierName}
                            </MenuItem>
                          );
                        })}
                  </Select>
                  <FormHelperText>
                    {touched.email && errors.email}
                  </FormHelperText>
                </FormControl>
                </>)}
            </Grid>
          </DialogContent>
          <Divider className={styleclasses.divider} />

          <DialogActions>
            <DialogActions>
              {!isSubmitting && (
                <Button
                  type="button"
                  onClick={cancleInvoice}
                  className={styleclasses.canceldialog}
                >
                  Cancel
                </Button>
              )}
              <Button
                type={isSubmitting ? "button" : "submit"}
                onClick={isSubmitting ? cancelInvoice : () => {}}
                className={styleclasses.submitdialog}
              >
                {isSubmitting ? "Cancel" : "Submit"}
              </Button>
            </DialogActions>
          </DialogActions>
        </form>
      </BootstrapDialog>
      {open && (
        <Dialog
          open={cancelModalflag}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure to cancel this upload?
          </DialogTitle>

          <DialogActions>
            <Button onClick={cancleToggle}>No</Button>
            <Button onClick={cancelInvoice} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Uploadinvoice;
