import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import InvoiceTable from "../Table/InvoiceTable";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MyteamTable from "../Table/MyteamTable";
import MetInvoiceTable from "../Table/MetInvoiceTable";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Metmyteamtable from "../Table/Metmyteamtable";
import { REPORT_URL } from "../comman/url";
import API from "../constants/API";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import Metsuppliertable from "../Table/Metsuppliertable";
import Metadvancetable from "../Table/Metadvancetable";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },

  tablerow: {
    background: "black !important",
  },
  tabtaneldiv: {
    padding: "0px 0px 24px 0px !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  tabtaneldiv: {
    padding: "0px !important",
    textAlign: "center !important",
    fontSize: "18px !important",
    letterSpacing: "0px !important",
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
  },
  autoapprovedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  advancedbutton: {
    textTransform: "none !important",
    boxShadow: "none",
    color: "#14142a50 !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  tabLabel: {
    display: "flex !important",
    alignItems: "center !important",
    flexDirection: "row !important",
  },
}));
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(1),
    color: "#A0A3BD",
    fontSize: "18px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
      fontWeight: 600,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: 600,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));

export default function MetricsInvoice(props) {
  const { classes } = props;
  const TeamReducer = useSelector((s) => s.TeamReducer);
  const styleclasses = useStyles();
  const [value, setValue] = React.useState("1");
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Grid sx={{ pt: 2 }} container spacing={2}>
        <Grid justifyContent="left" item xs={12} md={6}>
          <Typography align="left" variant="h4">
            Metrics
          </Typography>
        </Grid>
        <Grid item sx={{ paddingTop: "0 !important" }} xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            <img
              src={TeamReducer.companyLogo}
              alt=""
              className="companyLogo"
            ></img>
          </Stack>
        </Grid>
      </Grid>

      <Grid sx={{ pt: 3 }} container spacing={2}>
        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
          <Grid item xs={12} md={7} lg={7} sx={{ display: "flex" }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ bgcolor: "none" }}>
                <AntTabs
                  sx={{ borderBottom: "none" }}
                  value={value}
                  onChange={handleChange}
                  aria-label="ant example"
                >
                  {/* <AntTab label="Invoices" value="1" /> */}
                  <AntTab
                    className={styleclasses.tabLabel}
                    label={
                      <>
                        Invoices{" "}
                        {/* <Tooltip
                          title="Number of Invoices has made with Supplier Name and Invoice Number"
                          placement="top-start"
                          arrow
                          classes={{
                            arrow: styleclasses.arrow,
                            tooltip: styleclasses.tooltip,
                          }}
                        >
                          <InfoOutlinedIcon
                            style={{
                              color: "#A0A3BD",
                              width: "18px",
                              padding: "0px 1px",
                              marginLeft: "2px",
                              marginTop: "3px",
                            }}
                          />
                        </Tooltip> */}
                      </>
                    }
                    value="1"
                  />
                  <AntTab
                    className={styleclasses.tabLabel}
                    label={
                      <>
                        My Team{" "}
                        {/* <Tooltip
                          title="All invoices within EZ Cloud queried by specified duration"
                          placement="top-start"
                          arrow
                          classes={{
                            arrow: styleclasses.arrow,
                            tooltip: styleclasses.tooltip,
                          }}
                        >
                          <InfoOutlinedIcon
                            style={{
                              color: "#A0A3BD",
                              width: "18px",
                              padding: "0px 1px",
                              marginLeft: "2px",
                              marginTop: "3px",
                            }}
                          />
                        </Tooltip> */}
                      </>
                    }
                    value="2"
                  />
                  <AntTab
                    className={styleclasses.tabLabel}
                    // label="Suppliers"
                    label={
                      <>
                        Suppliers
                        {/* <Tooltip
                          title="Total invoices within EZ Cloud by supplier"
                          placement="top-start"
                          arrow
                          classes={{
                            arrow: styleclasses.arrow,
                            tooltip: styleclasses.tooltip,
                          }}
                        >
                          <InfoOutlinedIcon
                            style={{
                              color: "#A0A3BD",
                              width: "18px",
                              padding: "0px 1px",
                              marginLeft: "2px",
                              marginTop: "3px",
                            }}
                          />
                        </Tooltip> */}
                      </>
                    }
                    value="3"
                  />
                  {/* <AntTab
                    className={styleclasses.tabLabel}
                    // label="Advanced Metrics"
                    label={
                      <>
                        Advanced Metrics
                      
                      </>
                    }
                    value="4"
                  /> */}
                </AntTabs>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <TabContext value={value}>
          <Grid item xs={12} md={12}>
            {/* <MetInvoiceTable /> */}
            <TabPanel value="1" className={styleclasses.tabtaneldiv}>
              <MetInvoiceTable />
            </TabPanel>
            <TabPanel value="2" className={styleclasses.tabtaneldiv}>
              <Metmyteamtable />
            </TabPanel>
            <TabPanel value="3" className={styleclasses.tabtaneldiv}>
              <Metsuppliertable />
            </TabPanel>
            <TabPanel value="4" className={styleclasses.tabtaneldiv}>
              <Metadvancetable />
            </TabPanel>
          </Grid>
        </TabContext>
      </Grid>
    </>
  );
}
