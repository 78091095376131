import * as types from "../action/ActionType";

export default function Invoiceformdata(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_FORMDATA:
      return (state = payload);
    default:
      return state;
  }
}
