import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { GET_SUPPLIER_LIST_URL } from "../../comman/url";
import API from "../../constants/API";
import Swal from "sweetalert2";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../../comman/function";
import { authHeader, getAlertToast } from "../../comman/mainfunction";
const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
let filterInitial = {
  invoiceStatus: "",
  fromDate: "",
  toDate: "",
  supplier: "",
  approvedByRole: "",
};
export default function MetrixSupplierFilter(props) {
  const { openModal, closeModal, filterHandler, dataparams } = props;
  const styleclasses = useStyles();
  const [countSupplierMember, setcountSupplierMember] = useState();
  const [nameData, setNameData] = useState([]);

  const getSupplierList = async () => {
    const config = {
      method: "GET",
      url: GET_SUPPLIER_LIST_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() != "Supplier" ? getTeamID() : 0,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let decrpt_response = deCryptFun(response.data);
      const { data } = JSON.parse(decrpt_response);

      setNameData(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        console.log("error data new", v.message);
        //Swal.fire(getAlert("Error", v.message));
      }
    }
  };
  useEffect(() => {
    getSupplierList();
  }, []);
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      ...filterInitial,
      ...dataparams,
    },
    //validationSchema,
    enableReinitialize: true,
    onSubmit: (values) =>
      //  postToHandler(values),
      onSubmitFilter(values),
  });
  const {
    invoiceStatus = "",
    fromDate = "",
    toDate = "",
    supplier = "",
    approvedByRole = "",
  } = values;
  const postToHandler = (values) => {
    filterHandler(values);
    resetForm(filterInitial);
    closeModal();
  };
  const handleFromDate = (e) => {
    setValues((val) => ({
      ...val,
      fromDate:
        moment(e).format("YYYY-MM-DD") != "Invalid date"
          ? moment(e).format("YYYY-MM-DD")
          : "",
    }));
  };
  const supplierfilter = (e, val) => {
    console.log(e, val, "data");
  };

  const handleToDate = (e) => {
    setValues((val) => ({
      ...val,
      toDate:
        moment(e).format("YYYY-MM-DD") != "Invalid date"
          ? moment(e).format("YYYY-MM-DD")
          : "",
    }));
  };
  const resetField = () => {
    setValues({ ...filterInitial });
    // filterHandler(filterInitial);
  };
  const onSubmitFilter = (values) => {
    if (values.fromDate != "" && values.fromDate != "Invalid date") {
      if (values.toDate == "" || values.toDate == "Invalid date") {
        setFieldError("toDate", "To date is required");
        return null;
      }
    }
    if (values.toDate != "" && values.toDate != "Invalid date") {
      if (values.fromDate == "" || values.fromDate == "Invalid date") {
        setFieldError("fromDate", "From date is required");
        return null;
      }
    }
    postToHandler(values);
  };
  var result = nameData.reduce((unique, o) => {
    if (!unique.some((obj) => obj.supplierName === o.supplierName)) {
      unique.push(o);
    }
    return unique;
  }, []);

  const [edit, setEdit] = useState(false);
  const onChangeHandler = (event, newValue) => {
    setValues((val) => ({
      ...val,
      supplier: newValue.supplierName,
    }));
    setEdit(true);
  };

  const handleStatus = (e) => {
    if (e.target.value == "Reprocessing") {
      setValues((val) => ({
        ...val,
        fromDate: "",
        toDate: "",
        invoiceStatus: e.target.value,
      }));
    } else {
      setValues((val) => ({
        ...val,
        invoiceStatus: e.target.value,
      }));
    }
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openModal}
        classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A0A3BD",
              outline: "1px solid #A0A3BD",
              borderRadius: "50px",
            },
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeModal}
          >
            Filters
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Select Supplier
              </Typography>
              <FormControl sx={{ width: "100% !important" }} variant="standard">
                <Autocomplete
                  disableClearable
                  style={{ width: "100%" }}
                  name="supplier"
                  // value={supplier}
                  // onChange={handleChange}
                  value={{ supplierName: values.supplier || "" }}
                  className="supplierList"
                  onChange={(event, newValue) =>
                    // setValues((val) => ({
                    //   ...val,
                    //   supplier: newValue,
                    // }))
                    onChangeHandler(event, newValue)
                  }
                  options={result}
                  getOptionLabel={(option) => option.supplierName}
                  // disablePortal
                  id="combo-box-demo"
                  // options={
                  //   nameData && nameData.map((record, i) => record.supplierName)
                  // }
                  sx={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} />}
                />
                {/* <NativeSelect
                style={{fontFamily: "Montserrat"}}
                  id="demo-customized-select-native"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                
                  <option>Select Supplier</option>
                  {nameData &&
                    nameData.map((record, i) => {
                      const { supplierName } = record;
                      return (
                        <option key={i} value={supplierName}>
                          {supplierName}
                        </option>
                      );
                    })}
                </NativeSelect> */}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Invoice Status
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <NativeSelect
                  style={{ fontFamily: "Montserrat" }}
                  id="demo-customized-select-native"
                  name="invoiceStatus"
                  value={invoiceStatus}
                  onChange={handleStatus}
                  input={<BootstrapInput />}
                >
                  {/* <option aria-label="None" value="" /> */}
                  <option value={""}>All</option>
                  {[
                    "Approved",
                    "Pending",
                    "Auto Approved",
                    // "Validation Failure",
                    "Rejected",
                    "Initializing",
                    "Reprocessing",
                    "Request Information",
                    "Supplier Maintenance",
                    "Specialist Exception",
                    "Reject Invoice",
                  ].map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}> */}
            {/* <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                User Role
              </Typography> */}
            {/* <FormControl sx={{ width: "100%" }} variant="standard"> */}
            {/* <NativeSelect
                  style={{ fontFamily: "Montserrat" }}
                  id="demo-customized-select-native"
                  name="approvedByRole"
                  value={approvedByRole}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                > */}
            {/* <option aria-label="None" value="" /> */}
            {/* <option value={""}>All</option>
                  {[
                    "Admin",
                    "Team Memeber",
                    "Exception Handler",

                    // "Validation Failure",
                  ].map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })} */}
            {/* </NativeSelect>
              </FormControl>
            </Grid> */}
            {values.invoiceStatus != "Reprocessing" && (
              <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  From
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row" }}
                    spacing={3}
                  >
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      name="fromDate"
                      allowSameDateSelection
                      maxDate={
                        values.toDate && values.toDate != "Invalid date"
                          ? new Date(values.toDate)
                          : new Date()
                      }
                      value={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      renderInput={(params) => (
                        <TextField
                          onFocus={(e) => (e.target.placeholder = "MM/DD/YYYY")}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "From",
                          }}
                          error={errors.fromDate}
                          sx={{ width: "100% !important" }}
                          helperText={errors.fromDate ? errors.fromDate : ""}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            )}
            {values.invoiceStatus != "Reprocessing" && (
              <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  To
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row" }}
                    spacing={3}
                  >
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      name="toDate"
                      minDate={new Date(fromDate)}
                      allowSameDateSelection
                      value={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          onFocus={(e) => (e.target.placeholder = "MM/DD/YYYY")}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "To",
                          }}
                          error={errors.toDate}
                          helperText={errors.toDate ? errors.toDate : ""}
                          sx={{ width: "100% !important" }}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button type="submit" className={styleclasses.submitdialog}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
