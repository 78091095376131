import React, { useState } from "react";
import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  InputBase,
  NativeSelect,
  Grid,
  TextField,
  Box,
} from "@mui/material";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
} from "../../comman/function";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

const MyClientsFilter = ({
  open,
  addToggleClose,
  onReset,
  onFilterSubmit,
  clintFilter,
}) => {
  const styleclasses = useStyles();
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      ...clintFilter,
    },
    onSubmit: (values) => {
      onFilterSubmit(values);
    },
  });
  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={addToggleClose}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Filters
        </BootstrapDialogTitle>
        <Box component={"form"} onSubmit={handleSubmit}>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Company Name
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.companyName}
                name="companyName"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Status
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <NativeSelect
                  id="demo-customized-select-native"
                  value={values.requestStatus}
                  onChange={handleChange}
                  name="requestStatus"
                  input={<BootstrapInput />}
                >
                  <option aria-label="None" value="">
                    Show all
                  </option>
                  {["Accepted", "Pending", "Deactivated"].map((i) => {
                    return <option value={i}>{i}</option>;
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button onClick={onReset} className={styleclasses.canceldialog}>
                Reset
              </Button>
              <Button
                type="submit "
                onSubmit={handleSubmit}
                className={styleclasses.submitdialog}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
};

export default MyClientsFilter;
