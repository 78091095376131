import React, { Fragment, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Rocketmail from "../images/Rocketmail.png";
import { useNavigate } from "react-router-dom";
import { SIGNIN_ROUTE } from "../constants/RoutePaths";

const useStyles = makeStyles((theme) => ({
  shadow1: {
    width: "100%",
    backgroundColor: "unset !important",
    boxShadow: "none !important",
    padding: "24px",
    marginTop: "48px",
  },
  regimg: {
    width: "25%",
  },
  regtext: {
    width: "72%",
    display: "flex",
    margin: "auto !important",
    // [theme.breakpoints.up("md")]: {
    //   width: "100%",
    // },
  },
  pleasetext: {
    margin: "auto !important",
    fontSize: "14px !important",
    color: "#A0A3BD !important",
  },
  buttontext: {
    backgroundColor: "#EC7C37 !important",
    boxShadow: "none !important",
    "&:hover": {
      color: "#EC7C37 !important",
      border: "1px solid #EC7C37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
}));

export default function Registered(props) {
  const styleclasses = useStyles();
  let isFirsttime = useRef(true);
  let history = useNavigate();
  const { classes } = props;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const gotoSigin = () => {
    // console.log("ds");
    history(SIGNIN_ROUTE);
  };

  return (
    <Fragment>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
          columnSpacing={{ xs: 4, sm: 4, md: 4 }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Item className={styleclasses.shadow1}>
              <img src={Rocketmail} className={styleclasses.regimg}></img>
            </Item>

            <Grid item xs={12} md={12} lg={12} className={styleclasses.regtext}>
              <Typography align="center" variant="h6">
                You have succesfully registered with EZ Cloud. We have sent You
                a Confirmation Mail at your registered email address. To log
                into your account, you will need to click the provided link in
                the email received
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ p: 2, fontWeight: 600 }}>
              <Typography align="center" className={styleclasses.pleasetext}>
                Please check your spam/junk folder if you do not see any
                messages from EZ Cloud in your inbox.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ p: 2, fontWeight: 600 }}>
              <Button
                className={styleclasses.buttontext}
                variant="contained"
                onClick={() => gotoSigin()}
              >
                Signin
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}
