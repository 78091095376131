import { userDetails } from "../store/IntialStore";
import * as types from "../action/ActionType";

export default function UserReducer(state = userDetails, action) {
  const { type, payload,userName } = action;
  switch (type) {
    case types.ADD_USER_PROFILE:
      return payload;
    case types.UPDATE_USER_PROFILE:
      return { ...userDetails, profilePic: payload,userName:userName };
    default:
      return state;
  }
}
