import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import { deCryptFun, enCryptFun, getEmail } from "../comman/function";
import API from "../constants/API";
import { DELETE_TEAM_MEMBER } from "../comman/url";
import EditIcon from "@mui/icons-material/Edit";
import AddUpdateteam from "../components/Modal/AddUpdateteam";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import AddSubSupplier from "../components/Modal/AddSubSupplier";

import Loader from "../comman/Loader";
const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  activehead: {
    justifyContent: "space-evenly !important",
    display: "flex !important",
    textAlign: "-webkit-center !important",
    alignItems: "center !important",
  },
  tableContainerbg: {
    background: "white !important",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
    borderRadius: "12px !important",
    overflow: "hidden",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  let a_value = a[orderBy];
  let b_value = b[orderBy];
  if (
    orderBy === "firstName" ||
    orderBy === "lastName" ||
    orderBy === "email"
  ) {
    a_value = a_value?.toLowerCase();
    b_value = b_value?.toLowerCase();
  }
  if (b_value < a_value) {
    return -1;
  }
  if (b_value > a_value) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "firstName",
    numeric: true,
    disablePadding: false,
    label: " First Name",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  // {
  //   id: "position",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Position",
  // },
  // {
  //   id: "userRole",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "User Role",
  // },
  // {
  //   id: "approvalAmountTo",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Approval Threshold",
  // },
  // {
  //   id: "currency",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Currency",
  // },
  {
    id: "j",
    numeric: true,
    disablePadding: false,
    label: "Activate / Deactivate",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    // if (property !== "j") {
    onRequestSort(event, property);
    // }
  };
 
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={headCell.id !== "j" ? "custom-border" : ""}
            // className="custom-border"
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.id === "j"}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#4A9EF1",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function SubSupplierTable(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = React.useState("asc");
  const [memberFormdata, setmemberFormdata] = React.useState({});
  const [orderBy, setOrderBy] = React.useState("calories");
  const { supplierList,successCall} = props;
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [addmodalOpne, setaddmodalOpne] = React.useState(false);
  const addToggleClose = () => setaddmodalOpne((v) => !v);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const teamScuccesscall = () => {
    addToggleClose();
    // successCall();
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const activeTemamember = (event) => {
    const { checked, id } = event.target;
    Swal.fire({
      title: "Are you sure?",
      text: !checked
        ? "Do you want to deactivate this user?"
        : "Do you want to activate this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteMember(id, checked);
      }
    });
  };

  const deleteMember = async (memberId, isactive) => {
    let activeVal = !isactive ? 0 : 1;
    const configmember = {
      method: "DELETE",
      url: DELETE_TEAM_MEMBER,
      headers: authHeader(),
      params: {
        // userId: memberId,
        // isActive: isactive == 1 ? 0 : 1,
        webString: enCryptFun(
          JSON.stringify({
            userId: memberId,
            isActive: activeVal,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(configmember);
      //const { status, data, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, message } = JSON.parse(l);

      if (status === "Success") {
      
        CustomMessage(message, "success", enqueueSnackbar); //call toster

        successCall();
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      //Swal.fire(getAlertToast("error", message));
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // console.log("error data new", parsedErr.message);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editMember = (event) => {
    const { id } = event.currentTarget.dataset;
    let memberData = supplierList.find((va) => va.userId == id);
    console.log(memberData);
    setmemberFormdata({
      ...memberData,
      phoneNumber: memberData.countryCode + memberData.phoneNumber,
      
    });
    addToggleClose();
  };
  const successCallBack = () => {
    addToggleClose();
    successCall();
    
  };
 
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const { classes } = props;

  const styleclasses = useStyles();

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, borderRadius: "16px" }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className={styleclasses.tableContainerbg}>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
            {props.loading && (
                <TableRow align="center">
                  <TableCell colSpan={7}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {!props.loading && supplierList && supplierList.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <NoRecordFound />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {!props.loading && 
                  stableSort(supplierList, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, index)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {row.firstName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {row.lastName ? row.lastName :"-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {row.email}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                           
                            {row.position}
                          </TableCell> */}
                          {/* <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {row.userRole}
                          </TableCell> */}

                          {/*<TableCell align="center">
                            {row.phoneNumber}
                        </TableCell> */}
                          {/* <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {row.approvalAmountTo}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {row.currency ? row.currency : "USD"}
                          </TableCell> */}
                          <TableCell
                            className={styleclasses.activehead}
                            style={{
                              textAlign: "-webkit-center",
                              borderBottom: "none",
                            }}
                          >
                            <IconButton
                              data-id={row.userId}
                               onClick={editMember}
                              style={{ color: "#EC7C37" }}
                            >
                              <EditIcon />
                            </IconButton>
                         
                            {row.email == getEmail() ? (
                              ""
                            ) : (
                              <Stack
                                direction="row"
                                spacing={1}
                                style={{ display: "block", margin: "8px" }}
                              >
                                <AntSwitch
                                  id={row.userId}
                                  onChange={activeTemamember}
                                  checked={row.isActive === 1}
                                  inputProps={{ "aria-label": "ant design" }}
                                />
                              </Stack>
                            )}

                           
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </>
              )}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <AddSubSupplier
          addToggleClose={addToggleClose}
          open={addmodalOpne}
          initialData={memberFormdata}
          supplierData={supplierList}
          updateflag={true}
         
          successCallBack={successCallBack}
        />
    </Box>
  );
}
