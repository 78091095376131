import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { GET_USERS } from "../../comman/url";
import API from "../../constants/API";
import Swal from "sweetalert2";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../../comman/function";
import { authHeader, getAlertToast } from "../../comman/mainfunction";
const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));
let filterInitial = {
  status: "Approved",
  createdFromDate: "",
  createdToDate: "",
  userName: "",
  userRole: "",
};
export default function MetrixTeamMember(props) {
  const { openModal, closeModal, filterHandler, dataparams } = props;
  const styleclasses = useStyles();
  const [countTeamMember, setcountTeamMember] = useState();
  const [nameData, setNameData] = useState([]);

  //Api call
  let GetMember = async () => {
    const config = {
      method: "GET",
      url: GET_USERS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            userRole: "adminRole",
            count: countTeamMember,
          })
        ),
        flutterString: "",
      },
    };
    try {
      // setLoading(true)
      const response = await API(config);
      let l = deCryptFun(response.data);

      const { status, data, count } = JSON.parse(l);
      // const { status, data, count } = response.data;
      if (status === "Success") {
        console.log("team", data);
        setNameData(data);
        setcountTeamMember(count);
        // setLoading(false)
      }
    } catch (error) {
      //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        Swal.fire("Error", v.message);
      }
    } finally {
      //  setLoading(false);
    }
  };
  useEffect(() => {
    GetMember();
  }, [countTeamMember]);
  const postData = () => {};
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      ...filterInitial,
      ...dataparams,
    },
    //validationSchema,
    onSubmit: (values) =>
      //  postToHandler(values),
      onSubmitFilter(values),
  });
  const {
    status = "",
    createdFromDate = "",
    createdToDate = "",
    userName = "",
    userRole = "",
  } = values;
  const postToHandler = (values) => {
    filterHandler(values);
    closeModal();
  };
  const handleFromDate = (e) => {
    setValues((val) => ({
      ...val,
      createdFromDate: moment(e).format("YYYY-MM-DD"),
    }));
  };

  const handleToDate = (e) => {
    setValues((val) => ({
      ...val,
      createdToDate: moment(e).format("YYYY-MM-DD"),
    }));
  };
  const resetField = () => {
    // filterHandler(filterInitial);
    setValues({ ...filterInitial });
  };

  const onSubmitFilter = (values) => {
    if (
      values.createdFromDate != "" &&
      values.createdFromDate != "Invalid date"
    ) {
      if (
        values.createdToDate === "" ||
        values.createdToDate == "Invalid date"
      ) {
        setFieldError("createdToDate", "To date is required");
        return null;
      }
    }
    if (values.createdToDate != "" && values.createdToDate != "Invalid date") {
      if (
        values.createdFromDate === "" ||
        values.createdFromDate === "Invalid date"
      ) {
        setFieldError("createdFromDate", "From date is required");
        return null;
      }
    }
    postToHandler(values);
  };
  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openModal}
        classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A0A3BD",
              outline: "1px solid #A0A3BD",
              borderRadius: "50px",
            },
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeModal}
          >
            Filters
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Select User
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <NativeSelect
                  style={{ fontFamily: "Montserrat"}}
                  id="demo-customized-select-native"
                  name="userName"
                  value={userName}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                  {/* <option aria-label="None" value="" /> */}
                  <option value={""}>All</option>
                  {nameData &&
                    nameData.map((username, i) => {
                      const { userId, firstName, lastName } = username;
                      return (
                        <option key={i} value={firstName + " " + lastName}>
                          {firstName} {lastName}
                        </option>
                      );
                    })}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Invoice Status
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <NativeSelect
                  style={{ fontFamily: "Montserrat" }}
                  id="demo-customized-select-native"
                  name="status"
                  value={status}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                  {/* <option aria-label="None" value="" /> */}
                  <option value={""}>All</option>
                  {[
                    "Approved",
                    // "Pending",
                    // "Auto Approved",
                    // "Validation Failure",
                    "Rejected",
                    // "Initializing",
                    // "Reprocessing",
                    "Request Information",
                    "Supplier Maintenance",
                    "Specialist Exception",
                    "Reject Invoice",
                  ].map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                User Role
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <NativeSelect
                  style={{ fontFamily: "Montserrat" }}
                  id="demo-customized-select-native"
                  name="userRole"
                  // value={userRole}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                  <option value={""}>All</option>
                  {[
                    "Ap Manager",
                    "Ap Clerk",

                    // "Validation Failure",
                  ].map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                From
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack
                  sx={{ display: "flex", flexDirection: "row" }}
                  spacing={3}
                >
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    name="createdFromDate"
                    // maxdate={new Date()}
                    allowSameDateSelection
                    value={createdFromDate ? new Date(createdFromDate) : null}
                    onChange={handleFromDate}
                    maxDate={
                      values.createdToDate
                        ? new Date(values.createdToDate)
                        : new Date()
                    }
                    renderInput={(params) => (
                      <TextField
                        onFocus={(e) => (e.target.placeholder = "MM/DD/YYYY")}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "From",
                        }}
                        sx={{ width: "100% !important" }}
                        helperText={
                          // values.createdFromDate != "" && fromDateError
                          //   ? "Invalid Date"
                          //   : ""
                          errors.createdFromDate ? errors.createdFromDate : ""
                        }
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                To
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack
                  sx={{ display: "flex", flexDirection: "row" }}
                  spacing={3}
                >
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    name="createdToDate"
                    allowSameDateSelection
                    minDate={new Date(createdFromDate)}
                    value={createdToDate ? new Date(createdToDate) : null}
                    onChange={handleToDate}
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField
                        onFocus={(e) => (e.target.placeholder = "MM/DD/YYYY")}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "To",
                        }}
                        error={errors.createdToDate}
                        sx={{ width: "100% !important" }}
                        helperText={
                          errors.createdToDate ? errors.createdToDate : ""
                        }
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid> */}
          </DialogContent>
          <DialogActions style={{ padding: "8px 16px" }}>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button type="submit" className={styleclasses.submitdialog}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
