import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Mainlogocolor from "../images/Logo ez.png";
import Mainlogo from "../images/Logo_Color1.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  INVOICE_ROUTE,
  DASHBOARD_ROUTE,
  SUPPLIERMANAGEMENT_ROUTE,
  MYTEAM_ROUTE,
  SETTINGS_ROUTE,
  METRICSINVOICE_ROUTE,
  MYCLIENTS_ROUTE,
  SIGNIN_ROUTE,
  PROFILE_ROUTE,
  ADD_SUPPLIER,
  NOTIFICATION_ROUTE,
} from "../constants/RoutePaths";
import { Metricsicon } from "../comman/icons";
import {
  deCryptFun,
  enCryptFun,
  getLoginName,
  getRole,
  getUserId,
} from "../comman/function";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";

import Badge from "@mui/material/Badge";
import { UNLOCK_INVOICE_URL, USER_SIGN_OUT_URL } from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import API from "../constants/API";
import { CustomMessage } from "../comman/helperfunction";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { useSelector } from "react-redux";
import defaultprofile from "../images/defaultprofile.jpg";
import NotifyPopup from "./Notification/NotifyPopup";
import { getNotificationDetails } from "../redux/action";
import { useDispatch } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& .MuiDrawer-paper": {
      borderRight: "none !important",
    },
  },
  selected: {
    color: "#EC7C37 !important",
    backgroundColor: "transparent !important",
    transitionDuration: "0s !important",
    transition: "none !important",
    borderLeft: "2px solid #ec7c37",

    "& .MuiTouchRipple-root": {
      display: "none !important",
    },
    "& .Mui-focusVisible": {
      backgroundColor: "red !important",
    },
    "& .MuiListItemIcon-root": {
      color: "#EC7C37",
    },
    "&:hover": {
      background: "transparent !important",
      transitionDuration: "0s !important",
      transition: "none !important",
    },
  },

  menuroot: {
    borderLeft: "2px solid transparent !important",
    "& .MuiListItemIcon-root": {
      color: "#a0a3bd",
    },
    "& .MuiListItemText-root": {
      color: "#a0a3bd",
    },
    "& .MuiTouchRipple-root": {
      display: "none !important",
    },
    "&:hover": {
      background: "transparent !important",
      transitionDuration: "0s !important",
      transition: "none !important",
    },
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#f9f9fa",
  borderRight: "0px solid rgba(0, 0, 0, 0)",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#f9f9fa",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  backgroundColor: "#f9f9fa",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Sidebar() {
  const styleclasses = useStyles();
  const UserReducer = useSelector((s) => s.UserReducer);
  const invoiceList = useSelector((state) => state);
  const isEdit = useSelector((s) => s.EditReducer);
  console.log(invoiceList);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const invoiceId = localStorage.getItem("INVOICE_ID");
  //login menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popen = Boolean(anchorEl);
  const phandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  console.log("isedit", isEdit);
  const phandleClose = () => {
    setAnchorEl(null);
  };
  const history = useNavigate();
  const count = useSelector((countnot) => countnot.NotificationCountReducer);

  //main menu
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const profileRoute = () => {
    history(PROFILE_ROUTE);
  };
  let sorna = UserReducer.userName;
  let ss = sorna.substring(0, 11);
  console.log(
    "location path",
    location.pathname,
    location.pathname.toString().includes("/invoice")
  );
  const lockedFun = () => {
    if (isEdit) {
      CustomMessage(
        "Please Update/Cancel the invoice",
        "error",
        enqueueSnackbar
      ); //call toster
    }
  };
  const logOutFunction = () => {
    if (invoiceId) {
      unLockInvoice();
    } else {
      logOutuser();
    }
  };
  const unLockInvoice = async () => {
    const config = {
      method: "DELETE",
      url: UNLOCK_INVOICE_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceId,
            lockedBy: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      console.log("scuccess");
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (message == "Success") {
        logOutuser();
      }
    } catch (error) {
      console.log("error");
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        console.log("error data new", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster

        // Swal.fire(getAlert("Error", v.message));
      }
    } finally {
      logOutuser();
    }
  };

  const logOutuser = async () => {
    const config = {
      method: "PUT",
      url: USER_SIGN_OUT_URL,
      headers: authHeader(),
      data: {
        // userId: getUserId(),
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      console.log("from logout");
      const response = await API(config);
      // const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status == "Success") {
        CustomMessage("logout successful", "success", enqueueSnackbar); //call toster

        localStorage.clear();
        history(SIGNIN_ROUTE);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const imageStyle = {
    height: "42px",
    width: "40px",
    borderRadius: "10px",
    marginRight: "8px",
    objectFit: "cover",
  };
  const [openFlag, setFlag] = useState(false);
  const dispatch = useDispatch();

  const openList = () => {
    setFlag(!openFlag);
  };
  const viewMore = () => {
    // setFlag(!openFlag);
    history(NOTIFICATION_ROUTE);
  };
  useEffect(() => {
    dispatch(getNotificationDetails());
  }, []);
  return (
    <Box className={styleclasses.mainBox}>
      <CssBaseline />
      <AppBar
        sx={{ border: "none !important" }}
        position="fixed"
        open={open}
        style={{
          boxShadow: "none",
          background: "transparent",
          width: "0",
          left: "0",
        }}
      >
        <Toolbar sx={{ paddingLeft: "5px !important" }}>
          <IconButton
            style={{
              color: "#000",
              backgroundColor: "rgb(249 249 250)",
              padding: "5px",
            }}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <Link to={DASHBOARD_ROUTE}>
              <img
                src={Mainlogocolor}
                alt="ez cloud"
                style={{ marginRight: "6px", width: "34px", marginLeft: "6px" }}
              ></img>
            </Link>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          border: "none !important",
          borderRight: "0px solid rgba(0, 0, 0, 0)",
        }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader
          sx={{
            backgroundColor: "#f9f9fa !important",
            borderRight: "none !important",
          }}
        >
          <Link to={DASHBOARD_ROUTE}>
            <img src={Mainlogo} alt="ez cloud"></img>
          </Link>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <MenuIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <Box sx={{ borderRight: "none !important" }}>
          <List className="menusroot" sx={{ border: "none !important", mt: 2 }}>
            <Link
              to={isEdit ? "#" : DASHBOARD_ROUTE}
              onClick={isEdit ? lockedFun : () => {}}
            >
              <ListItemButton
                selected={location.pathname
                  .toString()
                  .includes(DASHBOARD_ROUTE)}
                className={
                  location.pathname.toString().includes(DASHBOARD_ROUTE)
                    ? styleclasses.selected
                    : styleclasses.menuroot
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  className={styleclasses.menulinkicon}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Link to={isEdit ? "#" : DASHBOARD_ROUTE}>Dashboard</Link>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
            <Link
              to={isEdit ? "#" : INVOICE_ROUTE}
              onClick={isEdit ? lockedFun : () => {}}
            >
              <ListItemButton
                selected={location.pathname.toString().includes(INVOICE_ROUTE)}
                className={
                  location.pathname.toString().includes(INVOICE_ROUTE)
                    ? styleclasses.selected
                    : styleclasses.menuroot
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Link to={isEdit ? "#" : INVOICE_ROUTE}>Invoices</Link>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
            {["Supplier", "Sub Supplier"].includes(getRole()) ? (
              <Link
                to={isEdit ? "#" : MYCLIENTS_ROUTE}
                onClick={isEdit ? lockedFun : () => {}}
              >
                <ListItemButton
                  selected={location.pathname
                    .toString()
                    .includes(MYCLIENTS_ROUTE)}
                  className={
                    location.pathname.toString().includes(MYCLIENTS_ROUTE)
                      ? styleclasses.selected
                      : styleclasses.menuroot
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link to={isEdit ? "#" : MYCLIENTS_ROUTE}>
                        My Clients
                      </Link>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : null}
            {["Supplier", "Sub Supplier"].includes(getRole()) ? (
              <Link
                to={isEdit ? "#" : ADD_SUPPLIER}
                onClick={isEdit ? lockedFun : () => {}}
              >
                <ListItemButton
                  selected={location.pathname.toString().includes(ADD_SUPPLIER)}
                  className={
                    location.pathname.toString().includes(ADD_SUPPLIER)
                      ? styleclasses.selected
                      : styleclasses.menuroot
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ShareIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link to={isEdit ? "#" : ADD_SUPPLIER}>My Team</Link>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : null}
            {getRole() === "Admin" ? (
              <Link
                to={isEdit ? "#" : MYTEAM_ROUTE}
                onClick={isEdit ? lockedFun : () => {}}
              >
                <ListItemButton
                  selected={location.pathname.toString().includes(MYTEAM_ROUTE)}
                  className={
                    location.pathname.toString().includes(MYTEAM_ROUTE)
                      ? styleclasses.selected
                      : styleclasses.menuroot
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link to={isEdit ? "#" : MYTEAM_ROUTE}>My Team</Link>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : null}
            {!["Supplier", "Sub Supplier"].includes(getRole()) ? (
              <Link
                to={isEdit ? "#" : SUPPLIERMANAGEMENT_ROUTE}
                onClick={isEdit ? lockedFun : () => {}}
              >
                <ListItemButton
                  selected={location.pathname
                    .toString()
                    .includes(SUPPLIERMANAGEMENT_ROUTE)}
                  className={
                    location.pathname
                      .toString()
                      .includes(SUPPLIERMANAGEMENT_ROUTE)
                      ? styleclasses.selected
                      : styleclasses.menuroot
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ShareIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link to={isEdit ? "#" : SUPPLIERMANAGEMENT_ROUTE}>
                        Suppliers
                      </Link>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : null}
            {getRole() === "Admin" ? (
              <Link
                to={isEdit ? "#" : METRICSINVOICE_ROUTE}
                onClick={isEdit ? lockedFun : () => {}}
              >
                <ListItemButton
                  selected={location.pathname
                    .toString()
                    .includes(METRICSINVOICE_ROUTE)}
                  className={
                    location.pathname.toString().includes(METRICSINVOICE_ROUTE)
                      ? styleclasses.selected
                      : styleclasses.menuroot
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <SignalCellularAltIcon sx={{ borderBottom: "2px solid" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link to={isEdit ? "#" : METRICSINVOICE_ROUTE}>
                        Metrics
                      </Link>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : null}{" "}
            {getRole() === "Admin" || getRole() === "Super Admin" ? (
              <Link
                to={isEdit ? "#" : SETTINGS_ROUTE}
                onClick={isEdit ? lockedFun : () => {}}
              >
                <ListItemButton
                  selected={location.pathname
                    .toString()
                    .includes(SETTINGS_ROUTE)}
                  className={
                    location.pathname.toString().includes(SETTINGS_ROUTE)
                      ? styleclasses.selected
                      : styleclasses.menuroot
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link to={isEdit ? "#" : SETTINGS_ROUTE}>
                        Administration
                      </Link>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : null}
          </List>
        </Box>
        {openFlag && <NotifyPopup openFlag={openFlag} openList={openList} />}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            position: "absolute",
            bottom: "1rem",
          }}
        >
          <IconButton
            onClick={phandleClick}
            size="small"
            sx={{
              ml: 1.5,
              color: "#14142A",
              fontWeight: 500,
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "unset",
              },
              "&:selected": {
                backgroundColor: "unset",
              },
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img
              src={
                UserReducer.profilePic != "" &&
                UserReducer.profilePic != "null" &&
                UserReducer.profilePic != undefined
                  ? UserReducer.profilePic
                  : defaultprofile
              }
              alt=""
              style={imageStyle}
            />{" "}
            {/* {open && UserReducer.userName} */}
            {open && <Typography>{ss.concat("....")}</Typography>}
            {/* < Badge sx={{ ml: 1, color: "#fff" }} badgeContent={4} color="error">
              <NotificationsIcon color="primary" />
            </Badge> */}
          </IconButton>
          <Tooltip title="Notifications">
            <Badge
              sx={{ ml: 1, color: "#fff" }}
              badgeContent={count}
              color="error"
            >
              <NotificationsIcon
                color="primary"
                onClick={count > 0 ? openList : viewMore}
                // onClose={openList}
              />
              {/* <NotificationsIcon color="primary" onClick={openList} /> */}
            </Badge>
          </Tooltip>
        </Box>
        <Menu
          elevation={0}
          style={{ borderRight: "unset", marginTop: "-55px" }}
          anchorEl={anchorEl}
          id="account-menu"
          open={popen}
          onClose={phandleClose}
          onClick={phandleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              // mt: 1,
              width: open ? "184px" : "67px",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                border: "non",
              },
              // '&:before': {
              //   content: '""',
              //   display: 'block',
              //   position: 'absolute',
              //   top: 0,
              //   right: 14,
              //   width: 10,
              //   height: 10,
              //   bgcolor: 'background.paper',
              //   transform: 'translateY(-50%) rotate(45deg)',
              //   zIndex: 0,
              // },
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <MenuItem
            onClick={profileRoute}
            style={{ borderBottom: "#e7e8ec solid 1px" }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {/* {getLoginName()} */}
            {open && "Profile"}
          </MenuItem>
          <MenuItem
            //  onClick={logOutuser}
            onClick={logOutFunction}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {open && "Logout"}
          </MenuItem>
        </Menu>
      </Drawer>
      <DrawerHeader />
      {/* <Outlet /> */}
    </Box>
  );
}

export default Sidebar;
