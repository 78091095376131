import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../../comman/helperfunction";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SIGNIN_ROUTE } from "../../constants/RoutePaths";

const useStyles = makeStyles((theme) => ({
  notifyapproverbtn: {
    margin: "0px 8px !important",
    fontWeight: "bold !important",
    fontSize: "13px !important",
    color: "#3779EC !important",
    border: "1px solid #3779EC !important",
    textTransform: "none !important",
  },
  ForgetPasswordResponsebtn: {
    margin: "0px 8px !important",
    fontSize: "14px !important",
    color: "#EC7C37 !important",
    border: "2px solid #EC7C37 !important",
    textTransform: "none !important",
    padding: "6px 23px",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    padding: "8px 25px",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
}));

export default function ForgetPasswordResponse(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { toggleOn, closeToggle, content } = props;
  const styleclasses = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const goSiginIn = () => {
    closeToggle();
    navigate(SIGNIN_ROUTE);
  };

  return (
    <Dialog
      style={{ borderRadius: "8px !important" }}
      fullScreen={fullScreen}
      open={toggleOn}
      // onClose={closeToggle}
      aria-labelledby="responsive-dialog-title"
    >
      {/* <DialogTitle id="responsive-dialog-title"></DialogTitle> */}
      <DialogContent style={{ padding: "25px 14px 15px" }}>
        <DialogContentText>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              color: "#000",
              mt: 2,
              lineHeight: "35px",
              textAlign: "center",
            }}
            variant="h4"
          >
            {content}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          padding: "20px 20px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          type="submit"
          autoFocus
          className={styleclasses.notifydownloadbtn}
          onClick={goSiginIn}
        >
          Sign In
        </Button>
      </DialogActions>
    </Dialog>
  );
}
