import React, { useEffect, useState, useRef } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as LineChartTooltip,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { debounce, initial } from "underscore";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

import { styled } from "@mui/material/styles";
import Advancemetrixfilter from "../components/Modal/Advancemetrixfilter";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { STANDARD_DEVIATION_URL, USER_EMAIL_LIST_URL } from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import PagenationPages from "../components/PagenationPages";
import Loader from "../comman/Loader";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    padding: "10px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    marginRight: "20px !important",
    width: "110px !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  tableContainerbg: {
    background: "white !important",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
    borderRadius: "12px !important",
    overflow: "hidden",
  },
  exportcsvmenu: {
    fontSize: "12px !important",
    letterSpacing: "0px !important",
    color: "#A0A3BD !important",
    padding: "5px 14px !important",
  },
  exportgrid: {
    top: "224px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
}));
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "senderEmail",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
  },
  {
    id: "stdDeviationAmount",
    numeric: true,
    disablePadding: false,
    label: "Standard Deviation",
  },

  {
    id: "invoiceCount",
    numeric: true,
    disablePadding: false,
    label: "Invoice Received",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={
              headCell.label !== "Invoice Received" ? "custom-border" : ""
            }
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export const AdvanceInvoiceAmountTable = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const { invoiceDataList = [], nameData } = props;
  const [loading, setLoading] = useState(false);
  const [filterModal, setfilterModal] = React.useState(false);
  const filterToggle = () => setfilterModal((v) => !v);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [totalData, setTotalData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [showexport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [recordsCsvData, setRecordsCsvData] = useState([]);
  // const [supp, setSupp] = useState("");
  // const [value, setValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openExport = Boolean(anchorEl);
  const [dataParams, setDataParams] = useState({
    count: 10,
    offset: 0,
    supp: "",
    value: "",
  });
  const [totalrecords, setTotalRecords] = useState(0);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getDeviationAmount = async (paramValues) => {
    const config = {
      method: "GET",
      url: STANDARD_DEVIATION_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            isRange: "",
            supplierEmail: paramValues.supp,
            count: paramValues.count,
            offset: paramValues.offset,
          })
        ),
        flutterString: "",
      },
    };

    try {
      setLoading(true);
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, totalCount } = JSON.parse(l);
      if (status == "Success") {
        //console.log("standard deviation data", data, level_1, level_2, level_3)
        setTotalData(data);
        setTotalRecords(totalCount);
        let level_1 = data[0].level_1;
        let level_2 = data[0].level_2;
        let level_3 = data[0].level_3;
        if (paramValues.value == 1) {
          setGraphData([
            {
              supplier: "",
              mean: "",
            },
            {
              supplier: "",
              mean: "",
            },
            {
              supplier: level_1.substring(0, level_1.indexOf("t")),
              mean: "0",
            },

            {
              supplier: paramValues.supp,
              mean: data && data[0].avarage,
            },

            {
              supplier: level_1.substring(
                level_1.indexOf("o") + 1,
                level_1.length
              ),
              mean: "0",
            },
            {
              supplier: "",
              mean: "",
            },
            {
              supplier: "",
              mean: "",
            },
          ]);
        } else if (paramValues.value == 2) {
          setGraphData([
            {
              supplier: "",
              mean: "",
            },
            {
              supplier: level_2.substring(0, level_2.indexOf("t")),
              mean: "0",
            },

            {
              supplier: paramValues.supp,
              mean: data && data[0].avarage,
            },

            {
              supplier: level_2.substring(
                level_2.indexOf("o") + 1,
                level_2.length
              ),
              mean: "0",
            },
            {
              supplier: "",
              mean: "",
            },
          ]);
        } else if (paramValues.value == 3) {
          setGraphData([
            {
              supplier: level_3.substring(0, level_3.indexOf("t")),
              mean: "0",
            },

            {
              supplier: paramValues.supp,
              mean: data && data[0].avarage,
            },

            {
              supplier: level_3.substring(
                level_3.indexOf("o") + 1,
                level_3.length
              ),
              mean: "0",
            },
          ]);
        } else {
          setGraphData([]);
        }
      }
    } catch (error) {
      console.log(error.response);
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  const fetchCsvData = async (paramValues) => {
    setExportLoading(true);
    const config = {
      method: "GET",
      url: STANDARD_DEVIATION_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            isRange: "",
            supplierEmail: paramValues.supp,
            count: totalrecords,
            offset: paramValues.offset,
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, totalCount } = JSON.parse(l);
      if (status == "Success") {
        setRecordsCsvData(data);
        setShowExport(true);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      // setLoading(false);
      setExportLoading(false);
    }
  };
  useEffect(() => {
    fetchCsvData(dataParams);
  }, [totalrecords, setTotalRecords]);

  const delaySearch = useRef(
    debounce((dataParams) => getDeviationAmount(dataParams))
  ).current;

  useEffect(() => {
    delaySearch(dataParams);
  }, [dataParams]);

  const headers = [
    { label: "Supplier Name", key: "senderEmail" },
    { label: "Standard Deviation", key: "stdDeviationAmount" },
    { label: "Invoice Received", key: "invoiceCount" },
  ];

  const getDownloadFileName = () => {
    return "InvoiceAmountReport";
  };
  const handleFilterSubmit = (filterValues) => {
    const { supp, value } = filterValues;

    setDataParams((data) => ({
      ...data,
      supp: supp,
      value: value,
      offset: 0,
    }));
    setfilterModal(false);
  };
  const onResetFilter = () => {
    setDataParams((data) => ({
      ...data,
      supp: "",
      value: "",
      offset: 0,
    }));
    setfilterModal(false);
  };
  const exportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportClose = () => {
    setAnchorEl(null);
  };
  const rowHandle = (pageCount) => {
    setDataParams((va) => ({
      ...va,
      count: pageCount.target.value,
      offset: 0,
    }));
  };
  const nextPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previousPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset - 1 }));
  };
  return (
    <>
      <Box>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ textAlign: "right", alignSelf: "end", mb: 2 }}
          className={styleclasses.exportgrid}
        >
          {/* <Tooltip
            title="Export as CSV"
            placement="top"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          > */}
          <Button
            className={styleclasses.notifydownloadbtn}
            variant="contained"
            id="basic-button"
            aria-controls={openExport ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openExport ? "true" : undefined}
            onClick={exportClick}
            disabled={exportLoading || showexport === false || loading}
          >
            Export
          </Button>
          {/* </Tooltip> */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openExport}
            onClose={exportClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <CSVLink
              key={recordsCsvData ? `csv_${recordsCsvData.length}` : ""}
              data={recordsCsvData}
              headers={headers}
              //ref={ref}
              filename={`${getDownloadFileName()}.csv`}
              target="_blank"
            >
              <MenuItem data-flag="csv" className={styleclasses.exportcsvmenu}>
                Export as CSV
              </MenuItem>
            </CSVLink>
          </Menu>
          <Tooltip
            title="Filter by"
            placement="top"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          >
            <IconButton onClick={filterToggle}>
              <FilterListRoundedIcon className={styleclasses.filtercolor} />
            </IconButton>
          </Tooltip>
          {filterModal && (
            <Advancemetrixfilter
              open={filterModal}
              handleClose={filterToggle}
              nameData={nameData}
              dataParams={dataParams}
              handleFilterSubmit={handleFilterSubmit}
              onResetFilter={onResetFilter}
            />
          )}
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Grid
              item
              xs={12}
              md={6}
              className={styleclasses.leftside}
              sx={{ padding: "0px 8px 0px 0px " }}
            >
              {/* <Invoiceamounttable /> */}
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer className={styleclasses.tableContainerbg}>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {loading && (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <Loader />
                        </TableCell>
                      </TableRow>
                    )}
                    {!loading && totalData.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <NoRecordFound />
                        </TableCell>
                      </TableRow>
                    )}

                    {!loading &&
                      stableSort(totalData, getComparator(order, orderBy)).map(
                        (list, index) => {
                          const {
                            senderEmail,
                            stdDeviationAmount,
                            invoiceCount,
                            invoiceId,
                          } = list;
                          const isItemSelected = isSelected(invoiceId);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, invoiceId)}
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={invoiceId}
                              selected={isItemSelected}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {senderEmail}
                              </TableCell>
                              <TableCell align="center">
                                {stdDeviationAmount}
                              </TableCell>
                              <TableCell align="center">
                                {invoiceCount}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <PagenationPages
                dataparams={dataParams}
                rowHandle={rowHandle}
                totalRecord={totalrecords}
                previorsPage={previousPage}
                nextPage={nextPage}
              />
              {/* <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                <Grid item xs={6} md={6} className={styleclasses.paginationrow}>
                  <Typography>
                    Rows Per Page:
                    <Button
                      className={styleclasses.rowsbutton}
                      endIcon={<ArrowDropDownRoundedIcon />}
                    >
                      10
                    </Button>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  className={styleclasses.paginationitem}
                >
                  <Typography className={styleclasses.paginationnum}>
                    1-10 of 203 items
                    <KeyboardArrowLeftRoundedIcon
                      className={styleclasses.iconpaginationleft}
                    />
                    <ChevronRightRoundedIcon
                      className={styleclasses.iconpaginationright}
                    />
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
            <Grid item xs={12} md={6} sx={{ padding: "0px 0px 0px 8px" }}>
              <Grid item xs={12} md={12}>
                {dataParams.supp ? (
                  totalData.length ? (
                    graphData.length ? (
                      <LineChart
                        width={500}
                        height={300}
                        // data={devData}
                        data={graphData}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        {/* <XAxis dataKey="senderEmail"> */}
                        <XAxis dataKey="supplier">
                          <Label value="" offset={0} position="insideBottom" />
                        </XAxis>
                        <YAxis
                          label={{
                            //value: "Invoice Amount",
                            angle: -90,
                            position: "insideLeft",
                          }}
                        />
                        <LineChartTooltip />
                        <Line
                          type="monotone"
                          // dataKey="stdDeviationAmount"
                          dataKey="mean"
                          stroke="#fd7e14"
                          fill="#fd7e14"
                        />
                      </LineChart>
                    ) : (
                      <img
                        src="http://siliconangle.com/files/2013/02/no-data.png"
                        alt="nodata"
                        height="150px"
                        width="150px"
                        className="mt-3"
                      />
                    )
                  ) : (
                    <img
                      src="http://siliconangle.com/files/2013/02/no-data.png"
                      alt="nodata"
                      height="150px"
                      width="150px"
                      className="mt-3"
                    />
                  )
                ) : (
                  "Select supplier to view deviation chart !"
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
