import React from "react";
import Dynamsoft from "dwt";
import { Row } from "reactstrap";
import { makeStyles } from "@mui/styles";
import { Button, ButtonBase } from "@mui/material";

export default class DWT extends React.Component {
  DWObject = null;
  containerId = "dwtcontrolContainer";

  state = {
    sources: [],
    selectedSource: null,
    blobUrl: "",
  };

  componentDidMount() {
    
    Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
      this.Dynamsoft_OnReady()
  });  
  // Dynamsoft.DWT.ProductKey = 't0153KQMAAJH47kLEqeLEDj8ILlJyfp7hVW6P9/44ZkkNU6RTGBxTehwhsniMBapaG/YN+J3/G9qa05lwnuQ8jAj7isiYaR02ksaGcAxyEelbyaa1MTzNqxitBjd1/qa8dWfeOC86dRrDCaNjU/rTN27mXw/pZ24NJ4yOjZu5MX6fU7B/PBSy18zSM5wwOjY189VsAZoZtw/G2p80';
  // Dynamsoft.DWT.ResourcesPath = "/dwt-resources";

  Dynamsoft.DWT.handshakeCode = '100583444-100583458';
  Dynamsoft.DWT.organizationID = '100583444';
  Dynamsoft.DWT.UseLocalService = 1;
  Dynamsoft.DWT.ResourcesPath = 'dwt-resources';
  
  Dynamsoft.DWT.Containers = [{
      WebTwainId: 'dwtObject',
      ContainerId: this.containerId,
      Width: '300px',
      Height: '400px'
  }];
  Dynamsoft.DWT.Load();
  }
  Dynamsoft_OnReady() {
    this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
    const sources = this.DWObject.GetSourceNames();
    this.setState({ sources, selectedSource: sources.length ? 0 : null });
  }
  acquireImage = () => {
    const handleBlobChange = this.props.handleBlobChange;
    this.DWObject.AcquireImage(
      (e) => {
       // this.DWObject.CloseSource();
        this.DWObject.ConvertToBlob(
          [...Array(1 + this.DWObject.ImageLayoutPageNumber).keys()],
          Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
          function (result, indices, type) {
            handleBlobChange(result);
            // console.log(URL.createObjectURL(result));
            // console.log(result);
          },
          function (errorCode, errorString) {
            console.log(errorString);
          }
        );

        console.log(e);
      },
      (e) => {
        //this.DWObject.CloseSource();
        console.log(e);
      }
    );
  };
  render() {
    return (
      <>
        <Row className="scandecor">
          <div id={this.containerId} className="scanimagecor">
            {" "}
          </div>
          <Button
            color="primary"
            className="scanbtn"
            onClick={() => this.acquireImage()}
          >
            {this.state.blobUrl ? "Re-scan" : "Scan"}
          </Button>
        </Row>
      </>
    );
  }
}
