import { combineReducers } from "redux";
import Requiredinvoice from "./Requiredinvoice";
import Requiredinvoicelineitem from "./Requiredinvoicelineitem";
import UserReducer from "./UserReducer";
import TeamReducer from "./TeamReducer";
import Invoiceformdata from "./Invoiceformdata";
import EditReducer from "./EditReducer";
import NotificationCountReducer from "./NotificationCountReducer";

const RootReducer = combineReducers({
  Requiredinvoice,
  Requiredinvoicelineitem,
  UserReducer,
  TeamReducer,
  Invoiceformdata,
  EditReducer,
  NotificationCountReducer,
});

export default RootReducer;
