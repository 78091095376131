import { deCryptFun, enCryptFun, getUserId } from "../../comman/function";
import { authHeader } from "../../comman/mainfunction";
import { GET_NOTIFICATION_URL } from "../../comman/url";
import API from "../../constants/API";
import { NOTIFY_COUNT } from "./ActionType";

export function getNotificationDetails() {
  return (dispatch) => {
    const config = {
      method: "GET",
      url: GET_NOTIFICATION_URL,

      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    API(config)
      .then((response) => {
        let l = deCryptFun(response.data);
        const { status, messageCount, readedMessages, unreadedMessages } =
          JSON.parse(l);
        if (status === "Success") {
          dispatch({
            type: NOTIFY_COUNT,
            payload: messageCount[0].totalCount,
          });
        }
      })

      .catch((error) => {
        if (error.response) {
          let { data } = error.response;
        }
      });
  };
}
