import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import InvoiceTable from "../Table/InvoiceTable";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import SupplierTable from "../Table/SupplierTable";
import SupplierTablensecond from "../Table/SupplierTablesecond";
import { getAlertToast, getAlert } from "../comman/mainfunction";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import Swal from "sweetalert2";
import API from "../constants/API";
import { CSVLink } from "react-csv";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
} from "../comman/function";
import { authHeader } from "../comman/mainfunction";
import {
  SUPPLIER_LISTING,
  UPDATE_SUPPLIER_STATUS,
  SUPPLIER_LISTING_RECLASIFICATION,
  ACCESS_S3_FILE,
} from "../comman/url";
import InviteSupplierModal from "./Modal/InviteSupplierModal";

import { debounce } from "underscore";
import Filtersupplier from "./Modal/Filtersupplier";
import Filtersuppliernew from "./Modal/Filtersuppliernew";
import { FormControl, MenuItem, Select } from "@mui/material";
import PagenationPages from "./PagenationPages";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablerow: {
    background: "black !important",
  },
  antfont: {
    fontSize: "18px !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
  },
  autoapprovedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  advancedbutton: {
    textTransform: "none !important",
    boxShadow: "none",
    color: "#14142a50 !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
}));
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#A0A3BD",
    //color: "rgba(0, 0, 0, 0.85)",
    fontSize: "14px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));
let filterInitial = {
  count: 10,
  offset: 0,
  companyName: "",
  // requestedName: "",
  requestStatus: "",
  supplierEmail: "",
  supplierCompanyName: "",
};

export default function MyTeam(props) {
  const { classes } = props;
  const { enqueueSnackbar } = useSnackbar();
  const TeamReducer = useSelector((s) => s.TeamReducer);
  const styleclasses = useStyles();
  const [value, setValue] = React.useState("1");
  const [requestData, setRequestData] = useState([]);
  const [requestDatanew, setRequestDatanew] = useState([]);
  const [signedUrl, setsignedUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalRecordsnew, setTotalRecordsnew] = useState(0);
  const [supplierFilter, setsupplierFilter] = useState(filterInitial);
  const [supplierFilternew, setsupplierFilternew] = useState(filterInitial);
  const [reqInviteSupplier, setReqInviteSupplier] = useState(false);
  const [reqFilterSupplier, setReqFilterSupplier] = useState(false);
  const [reqFilterSuppliernew, setReqFilterSuppliernew] = useState(false);
  const [filterSupplierList, setFilterSupplierList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRowsPerPage(event.target.value);
  };

  const requestListing = async (filtervalues) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: SUPPLIER_LISTING,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() === "Supplier" ? 0 : getTeamID(),
            offset: filtervalues.offset,
            count: filtervalues.count,
            // supplierCompanyName: filtervalues.supplierCompanyName,

            ...filtervalues,
            supplierEmail:
              getRole() === "Supplier"
                ? getEmail()
                : filtervalues.supplierEmail?.replace("'", "\\'"),
            supplierCompanyName: filtervalues.supplierCompanyName?.replace(
              "'",
              "\\'"
            ),
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      //const { data, status, count } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, count } = JSON.parse(l);
      setRequestData(
        data.map((item) => {
          return {
            ...item,
            supplierCompanyName: item.supplierCompanyName || "N/A",
            sentInvite: item.requestStatus,
          };
        })
      );
      setTotalRecords(count);
    } catch (error) {
      console.log(error.response);
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  const requestListing2 = async (filtervalues) => {
    console.log(filtervalues);
    setLoading(true);
    const config = {
      method: "GET",
      url: SUPPLIER_LISTING_RECLASIFICATION,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() === "Supplier" ? 0 : getTeamID(),
            offset: filtervalues.offset,
            count: filtervalues.count,
            // supplierCompanyName: filtervalues.supplierCompanyName,
            supplierName: filtervalues.supplierCompanyName,
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      //const { data, status, count } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, count, supplierName } = JSON.parse(l);

      var newset = [];
      console.log(data);

      await Promise.all(
        data.map(async (item) => {
          const config = {
            method: "POST",
            url: ACCESS_S3_FILE,
            data: {
              filePath: item.filePath,
            },
            headers: authHeader(),
          };
          try {
            const response = await API(config);
            const { status, url } = response.data;
            console.log(url);
            newset.push({
              url: url,
              name: item.supplierName,
              previewurl:
                "" +
                window.location.origin +
                "/invoice-preview/" +
                item.invoiceId,
            });
          } catch (error) {
            console.log(error);
          }
        })
      );

      setsignedUrl(newset);
      // if (filterSupplierList.length === 0) {
      // setFilterSupplierList(
      //   data.map((item) => {
      //     return {
      //       ...item,
      //       supplierName: item.supplierName || "N/A",
      //       sentInvite: item.requestStatus,
      //     };
      //   })
      // );

      // }
      setFilterSupplierList(supplierName);

      setRequestDatanew(
        data.map((item) => {
          return {
            ...item,
            supplierName: item.supplierName || "N/A",
            sentInvite: item.requestStatus,
          };
        })
      );
      setTotalRecordsnew(count);
    } catch (error) {
      console.log(error.response);
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const onHandleSupplierName = (e) => {
    setCurrentPage(0);
    const { value } = e.target;
    setsupplierFilter((v) => ({
      ...v,
      supplierCompanyName: value,
      requestStatus: "",
      offset: 0,
    }));
  };

  const delaySearch = useRef(
    debounce((supplierFilter) => requestListing(supplierFilter), 500)
  ).current;
  const delaySearch2 = useRef(
    debounce((supplierFilternew) => requestListing2(supplierFilternew), 500)
  ).current;

  useEffect(() => {
    delaySearch(supplierFilter);
  }, [supplierFilter]);

  useEffect(() => {
    requestListing2(supplierFilternew);
  }, [supplierFilternew]);

  const closeReqInviteSupplier = () => {
    setReqInviteSupplier(false);
  };

  const successReqInviteSupplierCallBack = () => {
    // setsupplierFilter((v) => ({
    // ...v,
    //   offset: 0,
    // }));
    requestListing(supplierFilter);
    setReqInviteSupplier(false);
  };

  const closeReqFilterSupplier = () => {
    setReqFilterSupplier(false);
  };
  const closeReqFilterSuppliernew = () => {
    setReqFilterSuppliernew(false);
  };
  const onResetFilterSupplier = () => {
    // setsupplierFilter((v) => ({
    //   ...v,
    //   requestedName: "",
    //   requestStatus: "",
    //   supplierEmail: "",
    // }));

    setReqFilterSupplier(false);
  };
  const onResetFilterSuppliernew = () => {
    // setsupplierFilter((v) => ({
    //   ...v,
    //   requestedName: "",
    //   requestStatus: "",
    //   supplierEmail: "",
    // }));

    setReqFilterSuppliernew(false);
  };

  const onFilterSubmit = (values) => {
    const { supplierCompanyName, supplierEmail, requestStatus } = values;

    setsupplierFilter((v) => ({
      ...v,
      supplierCompanyName: supplierCompanyName,
      requestStatus: requestStatus,
      supplierEmail: supplierEmail,
      offset: 0,
    }));

    setReqFilterSupplier(false);
  };

  const onFilterSubmitSecond = (values, value) => {
    const { supplierCompanyName, supplierEmail, requestStatus } = values;

    setsupplierFilternew((v) => ({
      ...v,
      supplierCompanyName: supplierCompanyName,
      requestStatus: requestStatus,
      supplierEmail: supplierEmail,
      offset: 0,
    }));

    setReqFilterSuppliernew(false);
  };

  const nextPage = () => {
    setsupplierFilter((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const nextPagenew = () => {
    setsupplierFilternew((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previorsPage = () => {
    setsupplierFilter((va) => ({ ...va, offset: va.offset - 1 }));
  };

  const previorsPagenew = () => {
    setsupplierFilternew((va) => ({ ...va, offset: va.offset - 1 }));
  };

  const rowHandle = (ve) => {
    setsupplierFilter((v) => ({ ...v, count: ve.target.value, offset: 0 }));
  };

  const rowHandlenew = (ve) => {
    setsupplierFilternew((v) => ({ ...v, count: ve.target.value, offset: 0 }));
  };

  const deleteSuccessCallback = () => {
    requestListing(supplierFilter);
  };

  const deleteSuccessCallbacknew = () => {
    requestListing2(supplierFilternew);
  };

  const headers = [
    { label: "Supplier Name", key: "name" },
    { label: "Download", key: "previewurl" },
  ];

  const downloadcsv = () => {
    CustomMessage(
      "Supplier documents exported sucessfully",
      "success",
      enqueueSnackbar
    );
    return true;
  };

  // const filterListingRequest = async () => {
  //   setLoading(true);
  //   const config = {
  //     method: "GET",
  //     url: SUPPLIER_LISTING_RECLASIFICATION,
  //     headers: authHeader(),
  //     params: {
  //       webString: enCryptFun(
  //         JSON.stringify({
  //           teamId: getRole() === "Supplier" ? 0 : getTeamID(),
  //           count: totalRecordsnew,
  //         })
  //       ),
  //       flutterString: "",
  //     },
  //   };

  //   try {
  //     const response = await API(config);
  //     //const { data, status, count } = response.data;
  //     let l = deCryptFun(response.data);
  //     const { status, data, count } = JSON.parse(l);

  //     var newset = [];
  //     console.log("table data response:", data);
  //     // if (filterSupplierList.length === 0) {
  //     setFilterSupplierList(
  //       data.map((item) => {
  //         return {
  //           ...item,
  //           supplierName: item.supplierName || "N/A",
  //           sentInvite: item.requestStatus,
  //         };
  //       })
  //     );
  //     // }
  //   } catch (error) {
  //     console.log(error.response);
  //     // //Swal.fire("Error", error);
  //     if (error.response) {
  //       let { data } = error.response;

  //       let p = deCryptFun(data);
  //       let v = JSON.parse(p);
  //       //  Swal.fire("Error", v.message);
  //       CustomMessage(v.message, "error", enqueueSnackbar); //call toster
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   filterListingRequest();
  // }, [totalRecordsnew]);

  return (
    <>
      <Grid sx={{ pt: 2 }} container spacing={2}>
        <Grid justifyContent="left" item xs={12} md={6}>
          <Typography align="left" variant="h4">
            Supplier Management
          </Typography>
        </Grid>
        <Grid item sx={{ paddingTop: "0 !important" }} xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            {/* <LightTooltip
              title=" Invite Supplier"
              placement="top"
              className={styleclasses.filterby}
            > */}
            {/* <Button
              className={styleclasses.notifydownloadbtn}
              variant="contained"
              onClick={() => setReqInviteSupplier(true)}
            >
              Invite Supplier
            </Button> */}
            {/* </LightTooltip> */}

            <img
              src={TeamReducer.companyLogo}
              alt=""
              className="companyLogo"
            ></img>
          </Stack>
        </Grid>
      </Grid>

      <Grid sx={{ pt: 3 }} container spacing={2}>
        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
          <TabContext value={value}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ bgcolor: "none" }}>
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
                  <Box sx={{ bgcolor: "none" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <AntTab
                        value="1"
                        label="Supplier List"
                        className={styleclasses.antfont}
                        style={{
                          fontWeight: 500,
                        }}
                      />
                      <AntTab
                        value="2"
                        label="Supplier Documents"
                        className={styleclasses.antfont}
                        style={{
                          fontWeight: 500,
                        }}
                      />
                    </TabList>
                  </Box>
                </Grid>

                <TabPanel value="1" sx={{ padding: "24px 0px 24px 0px" }}>
                  <Box sx={{ width: "100%" }}>
                    <Grid item xs={12} md={12}>
                      <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ display: "flex" }}
                        >
                          <Paper
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                              width: 500,
                              borderRadius: "8px",
                              boxShadow: "0px 1px 1px #00000029",
                            }}
                          >
                            <IconButton
                              type="submit"
                              sx={{ p: "10px" }}
                              aria-label="search"
                            >
                              <SearchIcon />
                            </IconButton>
                            <InputBase
                              sx={{
                                ml: 1,
                                flex: 1,
                              }}
                              placeholder="Search"
                              onChange={onHandleSupplierName}
                              value={supplierFilter.supplierCompanyName}
                              inputProps={{
                                "aria-label": "search google maps",
                              }}
                            />
                          </Paper>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ textAlign: "right" }}
                        >
                          <Button
                            className={styleclasses.notifydownloadbtn}
                            variant="contained"
                            onClick={() => setReqInviteSupplier(true)}
                          >
                            Invite Supplier
                          </Button>

                          <Tooltip
                            title="Filter by"
                            placement="top"
                            arrow
                            classes={{
                              arrow: styleclasses.arrow,
                              tooltip: styleclasses.tooltip,
                            }}
                          >
                            <IconButton
                              onClick={() => setReqFilterSupplier(true)}
                            >
                              <FilterListRoundedIcon
                                className={styleclasses.filtercolor}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={12} style={{ marginTop: "20px" }}>
                        {console.log("Data:", requestData)}

                        <SupplierTable
                          tableData={requestData}
                           requestListing={deleteSuccessCallback}
                          tableLoading={loading}
                          deleteSuccessCallback={deleteSuccessCallback}
                        />
                      </Grid>
                      <PagenationPages
                        dataparams={supplierFilter}
                        rowHandle={rowHandle}
                        totalRecord={totalRecords}
                        previorsPage={previorsPage}
                        nextPage={nextPage}
                      />
                    </Grid>
                  </Box>
                </TabPanel>
                <TabPanel value="2" sx={{ padding: "24px 0px 24px 0px" }}>
                  <Box sx={{ width: "100%" }}>
                    <Grid item xs={12} md={12}>
                      <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ textAlign: "right" }}
                        ></Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ textAlign: "right" }}
                        >
                          <CSVLink
                            key={
                              requestDatanew
                                ? `csv_${requestDatanew.length}`
                                : ""
                            }
                            data={signedUrl}
                            headers={headers}
                            //ref={ref}
                            filename={`supplier_documents.csv`}
                            target="_blank"
                            onClick={downloadcsv}
                          >
                            <Button
                              className={styleclasses.notifydownloadbtn}
                              variant="contained"
                              data-flag="csv"
                            >
                              Export
                            </Button>
                          </CSVLink>
                          <Tooltip
                            title="Filter by"
                            placement="top"
                            arrow
                            classes={{
                              arrow: styleclasses.arrow,
                              tooltip: styleclasses.tooltip,
                            }}
                          >
                            <IconButton
                              onClick={() => setReqFilterSuppliernew(true)}
                            >
                              <FilterListRoundedIcon
                                className={styleclasses.filtercolor}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} style={{ marginTop: "20px" }}>
                        {console.log("Data:", requestData)}

                        <SupplierTablensecond
                          tableData={requestDatanew}
                          // requestListing={requestListing}
                          tableLoading={loading}
                          deleteSuccessCallback={deleteSuccessCallbacknew}
                        />
                      </Grid>
                      <PagenationPages
                        dataparams={supplierFilternew}
                        rowHandle={rowHandlenew}
                        totalRecord={totalRecordsnew}
                        previorsPage={previorsPagenew}
                        nextPage={nextPagenew}
                      />
                    </Grid>
                  </Box>
                </TabPanel>
              </Box>
            </Box>
          </TabContext>
        </Grid>
      </Grid>
      {reqInviteSupplier && (
        <InviteSupplierModal
          open={reqInviteSupplier}
          addToggleClose={closeReqInviteSupplier}
          successReqInviteSupplierCallBack={successReqInviteSupplierCallBack}
          requestListing={requestListing}
        />
      )}
      {reqFilterSupplier && (
        <Filtersupplier
          open={reqFilterSupplier}
          addToggleClose={closeReqFilterSupplier}
          onReset={onResetFilterSupplier}
          onFilterSubmit={onFilterSubmit}
          supplierFilter={supplierFilter}
        />
      )}
      {console.log("Table data supplier:", filterSupplierList)}
      {reqFilterSuppliernew && (
        <Filtersuppliernew
          open={reqFilterSuppliernew}
          tableData={filterSupplierList}
          addToggleClose={closeReqFilterSuppliernew}
          onReset={onResetFilterSuppliernew}
          onFilterSubmit={onFilterSubmitSecond}
          supplierFilter={supplierFilternew}
        />
      )}
    </>
  );
}
