import React, { useRef } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";

import ConfigureStore from "./redux/store/ConfigureStore";
import reportWebVitals from "./reportWebVitals";
import { Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
const store = ConfigureStore();

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const CheckText = () => {
  const [checkValue, setcheckValue] = React.useState("");

  return (
    <TextField
      type={"text"}
      value={checkValue}
      onChange={(e) => setcheckValue(e.target.value)}
    />
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <Button onClick={onClickDismiss(key)}>
                <CloseIcon style={{ color: "#fff" }} />
              </Button>
            )}
            maxSnack={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <App />
            {/* <CheckText /> */}
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
