import React, { useState } from "react";
import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  InputBase,
  NativeSelect,
  Grid,
  TextField,
  Box,
} from "@mui/material";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
} from "../../comman/function";
import { useFormik } from "formik";
import { GENERATE_OTP, VERIFY_OTP } from "../../comman/url";
import { authHeader } from "../../comman/mainfunction";
import API from "../../constants/API";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../../comman/helperfunction";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

const VerifyPhoneNumber = ({
  open,
  addToggleClose,
  value,
  code,
  successCloseToggle,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const [confirmModal, setconfirmModal] = useState(false);
  const [otp, setOtp] = useState("");

  const onClose = () => {
    addToggleClose();
    resetForm({});
    setconfirmModal(false);
  };

  const GetOtp = async (values) => {
    const { phoneNumber, countryCode } = values;
    console.log("GET OTP:", values);
    const Number = phoneNumber.split(code)[1];
    const config = {
      method: "POST",
      url: GENERATE_OTP,
      headers: authHeader(),
      data: {
        // otpType: "Phone",
        // otpTo: Number,
        // countryCode: code,
        webString: enCryptFun(
          JSON.stringify({
            otpType: "Phone",
            otpTo: Number,
            countryCode: code,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (status === "Success") {
        CustomMessage("Otp Send Successfully", "success", enqueueSnackbar); //call toster
        setconfirmModal(true);
      } else {
        CustomMessage(message, "error", enqueueSnackbar); //call toster
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)

        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      //  setLoading(false);
    }
  };

  let OtpHandle = (e) => {
    setValues({ otp: e.target.value });
  };

  const VerifyOtp = async (values) => {
    const { phoneNumber, countryCode } = value;
    const Number = phoneNumber.split(code)[1];
    const config = {
      method: "PUT",
      url: VERIFY_OTP,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            otpType: "Phone",
            otpValue: values.otp,
            otpTo: Number,
            countryCode: code,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        // setreCall(reCall + 1);
        // setVerifyModal(false);
        successCloseToggle();
        resetForm({});
        CustomMessage("Otp Verified Successfully", "success", enqueueSnackbar); //call toster
        // setOtp("");
        setconfirmModal(false);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      //  setLoading(false);
    }
  };
  const validationSchema = Yup.object({
    otp: Yup.string().required("Please enter your otp"),
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    resetForm,
    errors,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: { otp: "" },
    validationSchema,
    onSubmit: (values) => {
      VerifyOtp(values);
    },
  });

  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
          // onClick={addToggleClose}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          {confirmModal
            ? "Verify Your OTP"
            : "Do you want to verify Mobile Number ?"}
        </BootstrapDialogTitle>
        <Box>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            {confirmModal ? (
              <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                <CssTextField
                  fullWidth
                  id="custom-css-outlined-input"
                  value={values.otp}
                  // onChange={handleChange}
                  onChange={OtpHandle}
                  name="otp"
                  onBlur={handleBlur}
                />
                <Typography>
                  {errors.otp && touched.otp ? errors.otp : ""}
                </Typography>
              </Grid>
            ) : null}
          </DialogContent>
          <DialogActions>
            <DialogActions>
              {confirmModal ? (
                <Button
                  className={styleclasses.submitdialog}
                  onClick={handleSubmit}
                >
                  Verify
                </Button>
              ) : (
                <Button
                  onClick={() => GetOtp(value)}
                  className={styleclasses.submitdialog}
                >
                  Yes
                </Button>
              )}
              <Button
                className={styleclasses.canceldialog}
                onClick={onClose}
                // onClick={addToggleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
};

export default VerifyPhoneNumber;
