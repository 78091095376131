import React, { Fragment, useEffect, useState } from "react";
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  ModalFooter,
  Form,
  FormFeedback,
} from "reactstrap";

import {

  Button,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Typography, FormHelperText } from "@mui/material";
import { Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";

import CustomButtonLoader from "../../comman/CustomButtonLoader";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  deCryptFun,
  enCryptFun,
  getRole,
  getSubTeamId,
  getTeamID,
  getUserId,
} from "../../comman/function";
import {
  GET_INVOICE_FIELDS,
  INVOICELINE_CREATE_URL,
  INVOICE_LINE_UPDATE_URL,
  SAVE_AUDIT,
  YARDI_DETAILS
} from "../../comman/url";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import API from "../../constants/API";
import {
  NUMBER_REGEX,
  LETTERS_NUMBERS_SYMBOLS_SPACE,
  LETTERS_NUMBERS_SYMBOLS,
  NUMBERS_SYMBOLS,
  NUMBERS_AND_SYMBOLS_REGEX,
  LETTERS,
  NUMBERS_DOT_REGEX,
  NUMBER_TWO_DECIMAL,
  NEGATIVE_NUMBERS
} from "../../comman/constants";
import Swal from "sweetalert2";
import { getSorting, stableSort } from "../../comman/function";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CustomMessage } from "../../comman/helperfunction";
import { useSnackbar } from "notistack";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import Box from "@mui/material/Box";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";
import Pagination from "@mui/material/Pagination";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-error fieldset": {
      borderColor: "red",
    },
    "& .MuiFormHelperText-root": {
      borderColor: "green",
    },
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    border: "1px solid green",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    border: "1px solid green",
  },
});
const acquireValidationColor = (message) => {
  switch (message) {
    case "Incorrect entry":
      return "green";
    case "Please input":
      return "orange";
    default:
      return "black";
  }
};
const useStyles = makeStyles((theme) => ({
  tools: {
    zIndex: 9,
    top: 0,
    left: 0,
  },
  paper: { maxWidth: "1400px", width: "100%" },
  previewbtn: {
    color: "#14142A !important",
  },
  popupclose: {
    position: "absolute",
    right: "13px",
    top: "0px",
    cursor: "pointer",
  },
  popupcloseicon: {
    fontSize: "30px",
    color: "gray",
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CreateInvoice(props) {
  const {
    createInvoiceModal,
    flag,
    invoiceToggle,
    formData,
    submitCallBack,
    invoiceID,
    dialogueView,
    type,
    GlCode,
    updateInvoiceLineItems,
    invoiceFilePath,
    extensionOfUrl,
    invoiceListlen,
    editInvoiceLine,
    indexvalue,
    previewUrl,
    propertylist,
    expenselist,
    getAudit,
  } = props;
  console.log(flag, "flag");
  //console.log("dialogueView", dialogueView)
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const subTeamId = getSubTeamId();
  const [requiredFieldList, setRequiredFieldList] = useState([]);
  const [glDate, setGlDate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [maxWidth, setMaxWidth] = React.useState("xl");
  const [gLDateError, setGlDateError] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [selectedpoId, setselectedpoId] = useState();
  const [yardiData, setYardiList] = useState([]);
  const styleclasses = useStyles();
  const [checkbox, setCheckbox] = useState({ show: false, checked: false });
  const transformOptionsPdf = {
    defaultScale: 1,
    defaultPositionX: 200,
    defaultPositionY: 100,
  };
  const transformOptionsPng = {
    defaultScale: 1,
    defaultPositionX: 0,
    defaultPositionY: 0,
  };

  const validationSchema = Yup.object({
    // operatingUnit,invoiceLineType,itemDescription,itemNumber
    operatingUnit: Yup.string()
      //.matches(LETTERS_NUMBERS_SYMBOLS, "Invalid Operating Unit")
      .test("operatingUnitTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "operatingUnit" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),

    invoiceLineNumber: Yup.string()
      // .matches(NUMBERS_SYMBOLS, "Invalid Invoice Line Number")
      .test("invoiceLineNumberTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceLineNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('invoiceLineNumberTest1', 'Invalid Invoice Line Number', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceLineNumber" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBERS_SYMBOLS.test(value) : true;
      })
      .nullable(),

    invoiceLineType: Yup.string()
      //.matches(LETTERS_NUMBERS_SYMBOLS, "Invalid Line Type")
      .test("invoiceLineTypeTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceLineType" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),

    extendedPrice: Yup.string()
      // .matches(NUMBERS_DOT_REGEX, "Invalid Extended Price")
      .test("extendedPriceTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "extendedPrice" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('extendedPriceTest1', 'Invalid Extended Price', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "extendedPrice" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NEGATIVE_NUMBERS.test(value) : true;
      })
      .nullable(),

    unitOfMeasure: Yup.string()
      // .matches(LETTERS, "Invalid Unit Of Measure")
      .test("unitOfMeasureTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "unitOfMeasure" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('unitOfMeasureTest1', 'Invalid Unit Of Measure', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "unitOfMeasure" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? LETTERS.test(value) : true;
      })
      .nullable(),
    itemDescription: Yup.string()
      .test("itemDescriptionTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "itemDescription" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })

      .nullable(),
    itemNumber: Yup.string()
      .test("itemNumberTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "itemNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    poLineNumber: Yup.string()
      // .matches(NUMBER_REGEX, "Invalid Po Line Number")
      .test("poLineNumberTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "poLineNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1 &&
            type == "po"
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('poLineNumberTest1', 'Invalid Po Line Number', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "poLineNumber" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBER_REGEX.test(value) : true;
      })
      .nullable(),
    poNumber: Yup.string()
      // .matches(LETTERS_NUMBERS_SYMBOLS, "Invalid Po Number")
      .test("poNumberTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "poNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1 &&
            type == "po"
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('poNumberTest1', 'Invalid Po Number', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "poNumber" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? LETTERS_NUMBERS_SYMBOLS.test(value) : true;
      })
      .nullable(),
    quantity: Yup.string()

      // .matches(NUMBER_REGEX, "Invalid Quantity")
      .test("quantityTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          console.log("quantity11");
          return (
            element.columnName === "quantity" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });



        return !value && found.length === 1 ? false : true;
      })
      .test('quantityTest1', 'Invalid Quantity', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "quantity" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBER_REGEX.test(value) : true;


      })
      .nullable(),
    unitPrice: Yup.string()
      // .matches(NUMBERS_DOT_REGEX, "Invalid Unit Price")
      .test("unitPriceTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "unitPrice" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .test('unitPriceTest1', 'Invalid Unit Price', (value) => {
        var found1 = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "unitPrice" &&
            element.isVisible === 1
          );
        });

        return value && found1.length === 1 ? NUMBERS_DOT_REGEX.test(value) : true;


      })
      .nullable(),
    glAccount: Yup.string()
      .test("glAccountTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "glAccount" &&
            element.isRequired === 1 &&
            element.isVisible === 1 &&
            type != "po"
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    GLDate: Yup.string()

      .test("glDateTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "GLDate" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    property: Yup.string()

      .test("poDetailIdTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "property" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    expenseAccount: Yup.string()

      .test("accountTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "expenseAccount" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),


  });

  const OnaddLNS = (event) => {
    if (
      LETTERS_NUMBERS_SYMBOLS.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }
    if (errors["poNumber"]) {
      handleChange(event);
    }

    // else{
    //   touched[event.target.name]=true

    // }
  };

  const onAddNS = (event) => {
    if (NUMBERS_SYMBOLS.test(event.target.value) || event.target.value == "") {
      handleChange(event);
    }
    if (errors["invoiceLineNumber"]) {
      handleChange(event);
    }
  };
  const onAddN = (event) => {
    if (NUMBER_REGEX.test(event.target.value) || event.target.value == "") {
      handleChange(event);
    }
    if (errors["poLineNumber"]) {
      handleChange(event);
    }
    if (errors["quantity"]) {
      handleChange(event);
    }
  };

  const onAddL = (event) => {
    if (LETTERS.test(event.target.value) || event.target.value == "") {
      handleChange(event);
    }
    if (errors["unitOfMeasure"]) {
      handleChange(event);
    }
  };
  const onAddND = (event) => {
    if (
      NUMBERS_DOT_REGEX.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }
    if (errors["unitPrice"]) {
      handleChange(event);
    }
    if (errors["extendedPrice"]) {
      handleChange(event);
    }
  };

  const onAddNDUNIT = (event) => {
    if (
      NUMBER_TWO_DECIMAL.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["unitPrice"]) {
      handleChange(event);
    }
  };

  const onAddNDEXTEND = (event) => {
    if (
      NUMBER_TWO_DECIMAL.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["extendedPrice"]) {
      handleChange(event);
    }
  };

  const onchnagefunc = {
    poNumber: OnaddLNS,
    poLineNumber: onAddN,
    unitOfMeasure: onAddL,
    quantity: onAddN,
    unitPrice: onAddNDUNIT,
    // extendedPrice: onAddNDEXTEND,
    //operatingUnit:OnaddLNS,
    invoiceLineNumber: onAddNS,
    //invoiceLineType:OnaddLNS
  };
  const saveFormValues = async (values, yardi = false,setFieldValue) => {

    const postData = {
      ...values, invoiceId: invoiceID,
      GLDate: values.GLDate != null || values.GLDate != "Invalid date" ? moment(new Date(values.GLDate)).format("yyyy/MM/DD") : "",
      glAccount: values.glAccount === "" ? "" : values.glAccount,
      propertyCheckbox: checkbox.checked === true || checkbox.checked === 1 ? 1 : 0
    }

    setLoading(true);
    const config = {
      method: flag ? "POST" : "PUT",
      url: flag ? INVOICELINE_CREATE_URL : INVOICE_LINE_UPDATE_URL,
      data: {
        webString: enCryptFun(
          JSON.stringify(postData)
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };

    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      // Swal.fire(
      //   getAlertToast(
      //     "success",
      // flag
      //   ? "Invoice Line created successfully!"
      //   : "Invoice Line Update successfully!"
      //   )
      // );
      if (flag) {
        // var secondBetweenTwoDate = Math.abs(
        //   (new Date().getTime() - timeproccess.getTime()) / 60000
        // );
        // invTime(secondBetweenTwoDate);
        saveAudit(`Line Item Created`);
        saveAudit(`Edit`);
      } else {
        // invoiceToggle(resetForm({}));

        // saveAudit("Invoice sssline");
      }
      if (yardi == false) {
        setGlDate("");
        CustomMessage(
          flag
            ? "Invoice Line created successfully!"
            : "Line item saved successfully!",
          "success",
          enqueueSnackbar
        ); //call toster
        setYardiList([]);
        setselectedpoId("")
        resetForm();
        submitCallBack();
        if (!flag && indexvalue < invoiceListlen) {
          editInvoiceLine();
        }
      }
      if (yardi == true) {
        getYardi();
      }

      if(checkbox.checked === 1) { 
        setFieldValue("property",values.property)
      }

    } catch (error) {
      // let { data } = error.response;
      // Swal.fire(getAlert("error", data));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
      setGlDate("");
      setCheckbox({ show: false, checked: false })
    }
  };

  const GetRequiredValues = async () => {
    const config = {
      method: "GET",
      url: GET_INVOICE_FIELDS,
      params: {
        // teamId: getRole() == "Supplier" ? subTeamId : getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() == "Supplier" ? subTeamId : getTeamID(),
          })
        ),
        flutterString: "",
      },

      headers: authHeader(),
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        // let requiredFields = data.filter((d) => { return d.isMandatory === 1});
        // console.log("fieldList data", data)
        setRequiredFieldList(data);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  //Formik
  const {
    handleSubmit,
    handleChange,
    values,
    setValues,
    errors,
    setErrors,
    touched,
    resetForm,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...formData,
      glAccount:
        (formData.glAccount == "" || formData.glAccount == null) &&
          flag &&
          type == "nonpo"
          ? GlCode
          : formData.glAccount,
      GLDate: formData.GLDate == "Invalid date" ? "" : formData.GLDate
    },
    validateOnMount: flag ? false : true,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => saveFormValues(values,false,setFieldValue),
  });
  const handleGlDate = (e) => {
    setValues((val) => ({
      ...val,
      GLDate: e,
    }));
  };
  useEffect(() => {
    GetRequiredValues();
  }, [subTeamId]);
  const op = requiredFieldList.filter(
    (element) =>
      element.columnName === "operatingUnit" && element.isMandatory === 1
  );
  const lineAmountRequired = requiredFieldList.filter(
    (element) =>
      element.columnName === "operatingUnit" && element.isMandatory === 1
  );

  console.log(requiredFieldList);

  let backColor = () => {
    if (
      loading === true ||
      Object.values(values).every((x) => x === null || x === "")
    ) {
      return "#f0f0f0";
    } else {
      return "rgb(236, 124, 55)";
    }
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    //setPageNumber(1);	
  }
  const [page, setPage] = React.useState(1);
  const handleChangepage = (event, value) => {
    console.log(value);
    setPage(value);
    setPageNumber(value);
  };

  const saveAudit = async (comment) => {
    const config = {
      method: "PUT",
      url: SAVE_AUDIT,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            comments: comment,
            actionBy: getUserId(),
            isExceptionResolved: false,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (message === "Success") {
        getAudit();
      }
    } catch (error) {}
  };
  const handlePropertyChange = (dataSelected) => {
    console.log("da", dataSelected);
    if (dataSelected !== null) {
      setValues((val) => ({
        ...val,
        property: dataSelected.property,
      }));

      for (let i = 0; i < propertylist.length; i++) {
        if (dataSelected.propertyId == propertylist[i].propertyId) {
          setCheckbox({ ...checkbox, show: true });
        }
      }
    } else {
      setValues((val) => ({
        ...val,
        property: null,
      }));
    }
  };
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.property + option.propertyDescription,
  });
  const handleAccountChange = (dataSelected) => {
    console.log(dataSelected, "acounthandle")
    if (dataSelected !== null) {
      setValues((val) => ({
        ...val,
        expenseAccount: dataSelected.account,
      }));
    } else {
      setValues((val) => ({
        ...val,
        expenseAccount: null,
      }));
    }
  };
  const filterOptionsAccount = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.account + option.accountDescription,
  });
  const updateValuesOnPO = (e) => {
    handleChange(e);

    let dataOfyardi = e.target.value
      ? yardiData.find((yd) => yd.poDetail == e.target.value)
      : "";
    if (e.target.value) {
      setValues((val) => ({
        ...val,
        poDetailId: e.target.value,
        itemDescription: dataOfyardi.poDetailDescription,
        property: dataOfyardi.Property,
        unitPrice: dataOfyardi.detailTotal,
      }));
    } else {
      // console.log(formData,"formData")
      setValues((val) => ({
        ...val,
        poDetailId: formData.poDetailId,
        itemDescription: formData.itemDescription,
        property: formData.property,
        unitPrice: formData.unitPrice,
      }));
    }
  };
  const getYardi = async () => {
    const config = {
      method: "GET",
      url: YARDI_DETAILS,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        // invoiceLineId: selectedInvoice,
        webString: enCryptFun(
          JSON.stringify({
            poNumber: values.poNumber,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status == "Success") {
        setYardiList(data);
        const divElement = document.getElementById("tableview");
        divElement.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      // console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        setYardiList([])
        setselectedpoId("")
        updateValuesOnPO1()
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  // useEffect(()=>{
  //   getYardi()
  // },[values.poNumber])

  const savePonumber = () => {
    saveFormValues(values, true);
  };
  const handleRadioChange = (e) => {

    setselectedpoId(e.target.value);
    updateValuesOnPO1(e.target.value);
  };

  const updateValuesOnPO1 = (idofpo) => {
    let dataOfyardi = idofpo ? yardiData.find((yd) => yd.poDetail == idofpo) : ""
    if (idofpo) {
      setValues((val) => ({
        ...val,
        poDetailId: idofpo,
        itemDescription: dataOfyardi.poDetailDescription,
        property: dataOfyardi.Property,
        extendedPrice: dataOfyardi.detailTotal,
        expenseAccount: dataOfyardi.account,
      }));
    } else {
      // console.log(formData,"formData")
      setValues((val) => ({
        ...val,
        poDetailId: "",
        // itemDescription: formData.itemDescription,
        // property: formData.property,
        // extendedPrice: formData.unitPrice,
      }));
    }
  };

  const closeModel = () => {
    setYardiList([]);
    setselectedpoId("")
    invoiceToggle(resetForm({}));
    setCheckbox({ show: false, checked: false })
  };

  const handleCheckbox = () => {
    if (checkbox.checked === 1 || checkbox.checked === true) setCheckbox({ ...checkbox, checked: 0 })
    else setCheckbox({ ...checkbox, checked: 1 })
  }

  useEffect(() => {

  }, [checkbox.checked])

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={createInvoiceModal}
        //maxWidth={maxWidth}  
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1400px",  // Set your width here
            },
          },
        }}
        //classes={{ paper: styleclasses.paper}}
        onClose={closeModel}
        aria-labelledby="responsive-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeModel}
        >
          {flag ? "Add Invoice Line Item" : "Update Invoice Line Item"}
        </BootstrapDialogTitle>

        <DialogContent style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Form form="true" onSubmit={handleSubmit}>
                {requiredFieldList &&
                  stableSort(
                    requiredFieldList.filter((i) => i.moduleName == "Invoice Line"),
                    getSorting("asc", "fieldOrder")
                  ).map((record, index) => {
                    const { columnName } = record;
                    //  console.log("value columnName", columnName, "value Values", values)
                    return requiredFieldList.filter(
                      (element) =>
                        element.columnName === record.columnName &&
                        element.isVisible === 1 &&
                        (type == "nonpo"
                          ? element.columnName != "poLineNumber" &&
                          element.columnName != "poNumber" &&
                          element.columnName != "poDetailId" &&
                          element.columnName != "glAccount"

                          : element.columnName != "glAccount")
                    ).length == 1 ? (
                      columnName == "GLDate" ? (
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, color: "#4E4B66" }}
                          >
                            {record.fieldName}{" "}
                            {requiredFieldList.filter(
                              (element) =>
                                element.columnName === columnName &&
                                element.isRequired === 1
                            ).length == 1
                              ? "*"
                              : ""}
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack sx={{ display: "flex", flexDirection: "row" }}>
                              <DesktopDatePicker
                                allowSameDateSelection
                                closeOnScroll={true}
                                // value={new Date(glDate)}
                                name="GLDate"
                                value={
                                  values.GLDate ? new Date(values.GLDate) : null
                                }
                                // onChange={(date) => {
                                //   setGlDate(new Date(date));
                                // }}
                                onChange={handleGlDate}
                                disabled={dialogueView == "viewLineItem"}
                                onError={(e) => {
                                  if (
                                    e === "invalidDate" ||
                                    e === "minDate" ||
                                    e === "maxDate" ||

                                    values.GLDate !== null
                                  ) {
                                    setGlDateError(true);
                                    setErrors((val) => ({
                                      ...val,
                                      GLDate: "Field is required",
                                    }));
                                  } else {
                                    setGlDateError(false);
                                  }
                                }}
                                error={gLDateError}
                                onBlur={handleBlur}
                                // helperText="Invalid Date"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ width: "100% !important" }}
                                    helperText={
                                      errors.GLDate
                                        ? errors.GLDate
                                        : gLDateError
                                          ? "Invalid Date"
                                          : ""
                                    }
                                    error={errors.GLDate}
                                  />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                          <FormHelperText id="standard-error-helper-text" error>
                            {glDate != "" || values.GLDate != ""
                              ? ""
                              : errors[record.columnName]
                                ? errors[record.columnName]
                                : ""}
                          </FormHelperText>
                        </Grid>
                      ) :
                        columnName == "poDetailId" ? (
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, color: "#4E4B66" }}
                            >
                              {record.fieldName}{" "}
                              {requiredFieldList.filter(
                                (element) =>
                                  element.columnName === columnName &&
                                  element.isRequired === 1
                              ).length == 1
                                ? "*"
                                : ""}
                            </Typography>
                            <CssTextField

                              name="poDetailId"
                              id="poDetailId"
                              onChange={updateValuesOnPO}
                              value={values.poDetailId}
                              sx={{ width: "100% !important" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            // helperText={gLDateError ? "Invalid Date" : ""}
                            />

                          </Grid>
                        ) :

                          // columnName=="glAccount"?(
                          //   <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                          //     <Typography
                          //       gutterBottom
                          //       sx={{ fontWeight: 600, color: "#4E4B66" }}
                          //     >
                          //     {record.fieldName}{" "}
                          //       {requiredFieldList.filter(
                          //         (element) =>
                          //           element.columnName === columnName &&
                          //           element.isRequired === 1
                          //       ).length == 1
                          //         ? "*"
                          //         : ""}
                          //     </Typography>

                          //     <CssTextField
                          //       fullWidth
                          //       name={columnName}
                          //       id={columnName}
                          //       placeholder={`Enter ${columnName}`}
                          //       // invalid={
                          //       //   errors[columnName]
                          //       //     ? true
                          //       //     : false
                          //       // }
                          //       value={
                          //         // columnName == "glAccount"
                          //         //   ? GlCode
                          //         //   : values[columnName]
                          //         //   ? values[columnName]
                          //         //   : ""

                          //         values[columnName] ? values[columnName] : ""
                          //       }
                          //       onBlur={handleBlur}
                          //       onChange={handleChange}
                          //       error={errors[columnName]}
                          //       disabled={dialogueView == "viewLineItem"}
                          //       renderInput={(params) => (
                          //         <TextField
                          //           {...params}
                          //           sx={{ width: "100% !important" }}
                          //           // helperText={gLDateError ? "Invalid Date" : ""}
                          //         />
                          //       )}
                          //     />
                          //     <FormHelperText id="standard-error-helper-text" error>
                          //       {errors[columnName] ? errors[columnName] : ""}
                          //       {console.log(errors[columnName])}
                          //     </FormHelperText>
                          //   </Grid>
                          // ):


                          columnName == "property" ? (
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, color: "#4E4B66" }}
                              >
                                {record.fieldName}{" "}
                                {requiredFieldList.filter(
                                  (element) =>
                                    element.columnName === columnName &&
                                    element.isRequired === 1
                                ).length == 1
                                  ? "*"
                                  : ""}
                              </Typography>
                              <Autocomplete
                                disablePortal


                                onChange={(_event, dataSelected) => {
                                  handlePropertyChange(dataSelected, "property");
                                }}

                                value={
                                  values.property
                                    ? `${values.property} - ${propertylist.find(
                                      (o) => o.property == values.property
                                    ).propertyDescription
                                    }`
                                    : "" || ""
                                }
                                name="property"
                                id="property"
                                placeholder="Property"
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                // selectOnFocus
                                // clearOnBlur
                                // handleHomeEndKeys
                                options={propertylist}
                                filterOptions={filterOptions}
                                getOptionSelected={(option, value) =>
                                  option.propertyId == value
                                }
                                getOptionLabel={(option) => {
                                  if (typeof option === "number") {
                                    let obj = propertylist.find(
                                      (o) => o.propertyId == option
                                    );
                                    return obj.property;
                                  }
                                  if (typeof option === "string") {
                                    return option;
                                  }

                                  return option.property;
                                }}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    {option.property} - {option.propertyDescription}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select Property"
                                  />
                                )}
                              />
                              <FormHelperText id="standard-error-helper-text" error>
                                {errors[columnName] ? errors[columnName] : ""}
                              </FormHelperText>
                              {checkbox.show
                                ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px 0px" }}>
                                  <input type="checkbox" checked={checkbox.checked === 1 || checkbox.checked === true ? true : false}
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={handleCheckbox} /> {"Update all line items"}
                                </div> : ""}
                            </Grid>
                          ) : columnName == "expenseAccount" ? (
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, color: "#4E4B66" }}
                              >
                                {record.fieldName}{" "}
                                {requiredFieldList.filter(
                                  (element) =>
                                    element.columnName === columnName &&
                                    element.isRequired === 1
                                ).length == 1
                                  ? "*"
                                  : ""}
                              </Typography>
                              <Autocomplete
                                disablePortal
                                // disabled={!isEdit}
                                //onChange={handleNameChange}

                                onChange={(_event, dataSelected) => {
                                  handleAccountChange(dataSelected);
                                }}


                                value={values.expenseAccount ? `${values.expenseAccount} - ${expenselist.find(
                                  (o) => o.account == values.expenseAccount
                                )?.accountDescription}` : "" || ""

                                }

                                name="expenseAccount"
                                id="expenseAccount"
                                placeholder="expenseAccount"
                                inputProps={{
                                  "aria-label": "Without label",
                                }}

                                options={expenselist}
                                filterOptions={filterOptionsAccount}
                                // getOptionSelected={(option, value) => option.propertyId == value }
                                getOptionLabel={(option) => {
                                  if (typeof option === "number") {
                                    let obj = expenselist.find(
                                      (o) => o.expenseId == option
                                    );
                                    return obj.account;
                                  }
                                  if (typeof option === "string") {
                                    return option;
                                  }

                                  return option.account;
                                }}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    {option.account} - {option.accountDescription}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select Account"
                                  />
                                )}
                              />
                              <FormHelperText id="standard-error-helper-text" error>
                                {errors[columnName] ? errors[columnName] : ""}
                              </FormHelperText>
                            </Grid>
                          ) : columnName == "poNumber" ? (
                            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, color: "#4E4B66" }}
                              >
                                {record.fieldName}{" "}
                                {requiredFieldList.filter(
                                  (element) =>
                                    element.columnName === columnName &&
                                    element.isRequired === 1
                                ).length == 1
                                  ? "*"
                                  : ""}
                                <Tooltip
                                  title="Save to view related PO Details"
                                  placement="right-start"
                                  arrow


                                >
                                  <InfoOutlinedIcon
                                    style={{
                                      color: "#A0A3BD",
                                      width: "15px",
                                      padding: "0px 1px",
                                      paddingTop: "11px",
                                    }}
                                  />
                                </Tooltip>
                              </Typography>
                              <div style={{ position: "relative" }}>
                                <CssTextField
                                  fullWidth
                                  name={columnName}
                                  id={columnName}
                                  placeholder={`Enter ${record.fieldName}`}

                                  error={errors[columnName]}
                                  value={
                                    values[columnName] ? values[columnName] : ""
                                  }
                                  // onBlur={handleBlur}
                                  onChange={
                                    onchnagefunc[columnName] || handleChange
                                  }
                                  disabled={dialogueView == "viewLineItem"}
                                />
                                <Button
                                  onClick={savePonumber}
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "9px",
                                  }}
                                >
                                  Save
                                </Button>
                              </div>
                              <FormHelperText id="standard-error-helper-text" error>
                                {errors[columnName] ? errors[columnName] : ""}
                              </FormHelperText>
                            </Grid>
                          ) :


                            (
                              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                                <Typography
                                  gutterBottom
                                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                                >
                                  {record.fieldName}{" "}
                                  {requiredFieldList.filter(
                                    (element) =>
                                      element.columnName === columnName &&
                                      element.isRequired === 1
                                  ).length == 1
                                    ? "*"
                                    : ""}
                                </Typography>
                                <CssTextField
                                  fullWidth
                                  name={columnName}
                                  id={columnName}
                                  placeholder={`Enter ${record.fieldName}`}
                                  // invalid={
                                  //    errors[record.columnName]? true
                                  //    : false

                                  //   // touched[record.columnName]

                                  // }
                                  error={errors[columnName]}
                                  value={
                                    values[columnName]
                                      ? values[columnName]
                                      : columnName == "glAccount"
                                        ? GlCode
                                        : ""
                                  }
                                  // onBlur={handleBlur}
                                  onChange={onchnagefunc[columnName] || handleChange}
                                  disabled={dialogueView == "viewLineItem"}
                                />
                                <FormHelperText id="standard-error-helper-text" error>
                                  {errors[columnName] ? errors[columnName] : ""}
                                </FormHelperText>
                              </Grid>
                            )
                    ) : (
                      ""
                    );
                  })}

                {type === "nonpo" &&
                  requiredFieldList &&
                  requiredFieldList.filter(
                    (element) => element.columnName === "glAccount"
                  ).length == 1 &&
                  requiredFieldList
                    .filter((element) => element.columnName === "glAccount" && element.isVisible == 1)
                    .map((el) => {
                      const { columnName, fieldName } = el;

                      return (
                        <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, color: "#4E4B66" }}
                          >
                            {fieldName}{" "}
                            {requiredFieldList.filter(
                              (element) =>
                                element.columnName === columnName &&
                                element.isRequired === 1
                            ).length == 1
                              ? "*"
                              : ""}
                          </Typography>

                          <CssTextField
                            fullWidth
                            name={columnName}
                            id={columnName}
                            placeholder={`Enter ${columnName}`}
                            // invalid={
                            //   errors[columnName]
                            //     ? true
                            //     : false
                            // }
                            value={
                              // columnName == "glAccount"
                              //   ? GlCode
                              //   : values[columnName]
                              //   ? values[columnName]
                              //   : ""

                              values[columnName] ? values[columnName] : ""
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={errors[columnName]}
                            disabled={dialogueView == "viewLineItem"}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ width: "100% !important" }}
                              // helperText={gLDateError ? "Invalid Date" : ""}
                              />
                            )}
                          />
                          <FormHelperText id="standard-error-helper-text" error>
                            {errors[columnName] ? errors[columnName] : ""}
                            {console.log(errors[columnName])}
                          </FormHelperText>
                        </Grid>
                      );
                    })}

                <DialogActions>
                  <Button onClick={closeModel}>
                    Close
                  </Button>
                  {dialogueView == "viewLineItem" ? null : (
                    <Button
                      color="primary"
                      type="submit"
                      style={{
                        backgroundColor:
                          loading === true ||
                            Object.values(values).every((x) => x === null || x === "")
                            ? "#f0f0f0"
                            : "rgb(236, 124, 55)",
                        color: "#fff",
                        width: "100px",
                        height: "43px",
                      }}
                      disabled={
                        gLDateError ||
                        loading === true ||
                        Object.values(values).every((x) => x === null || x === "")
                      }
                      sx={{
                        "&:disabled": {
                          backgroundColor: "#A0A3BD !important",
                        },
                      }}
                    >
                      {flag ? "Add" : "Update"}
                    </Button>
                  )}
                </DialogActions>
              </Form>
            </Grid>
            <Grid item xs={7}>
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <TransformWrapper
                  options={
                    extensionOfUrl == "pdf" || extensionOfUrl == "PDF" 
                      ? transformOptionsPdf
                      : transformOptionsPng
                  }
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <Fragment>
                      {flag && (
                        <>
                          <Typography variant="h4">Invoice</Typography>
                          <Box className={styleclasses.tools}>
                            <IconButton
                              aria-label="add"
                              className={styleclasses.previewbtn}
                            >
                              <AddCircleOutlineRoundedIcon onClick={zoomIn} />
                            </IconButton>
                            <IconButton
                              aria-label="out"
                              className={styleclasses.previewbtn}
                            >
                              <RemoveCircleOutlineRoundedIcon
                                onClick={zoomOut}
                              />
                            </IconButton>
                          </Box>
                        </>
                      )}

                      {/* <canvas id="myCanvas" height="100" weight="100"></canvas> */}
                      <TransformComponent>
                        {/* {url && ( */}
                        {extensionOfUrl == "pdf" || extensionOfUrl == "PDF" ? (
                          <Document
                            className="popuppdf"
                            file={previewUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            // onLoadError={onDocumentError}
                            overflow="scroll"
                            width="100"
                            noData="Loading PDF..."
                          >
                            <Page
                              pageNumber={pageNumber}
                            // width="100"
                            // overflow="scroll"
                            />
                          </Document>
                        ) : (
                          <img
                            className="pdfview"
                            style={{ width: "100%" }}
                            src={previewUrl}
                            noData="Loading File..."
                          ></img>
                        )}
                      </TransformComponent>
                    </Fragment>
                  )}
                </TransformWrapper>
                {numPages ? (
                  <Stack spacing={2}>
                    <Pagination
                      count={numPages}
                      page={page}
                      onChange={handleChangepage}
                    />
                  </Stack>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          {yardiData && yardiData.length > 0 ? (

            <div id="tableview">
              <h3> Select the PO Detail ID</h3>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#F0F1F5" }}>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>PO Number</TableCell>
                      <TableCell>PO Description</TableCell>
                      <TableCell>Person</TableCell>
                      <TableCell>PO Total</TableCell>
                      <TableCell>PO Detail ID</TableCell>
                      <TableCell>PO Detail Description</TableCell>
                      <TableCell>Property</TableCell>
                      <TableCell>Account</TableCell>
                      <TableCell>Detail Total</TableCell>
                      <TableCell>Amt Remaining</TableCell>
                    </TableRow>
                  </TableHead>
                  {yardiData.map((yd) => {
                    const {
                      poDetail,
                      poDescription,
                      person,
                      poTotal,
                      poNumber,
                      poDetailDescription,
                      Property,
                      account,
                      detailTotal,
                      amtRemaining,
                    } = yd;
                    return (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {/* <RadioGroup
                             aria-labelledby="demo-error-radios"
                             value={selectedpoId}
                             onChange={handleRadioChange}
                             name="radio-buttons-group"
                           >
                             <FormControlLabel
                               value={poDetail}
                               control={<Radio />}
                               
                             />{" "}
                           </RadioGroup> */}
                            <Radio
                              checked={selectedpoId === poDetail}
                              onChange={handleRadioChange}
                              value={poDetail}
                              name="radio-buttons"
                            // inputProps={{ 'aria-label': 'A' }}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {poNumber}
                          </TableCell>

                          <TableCell align="right">
                            {poDescription}
                          </TableCell>
                          <TableCell align="right">{person}</TableCell>
                          <TableCell align="right">{poTotal}</TableCell>
                          <TableCell align="right">{poDetail}</TableCell>
                          <TableCell align="right">
                            {poDetailDescription}
                          </TableCell>
                          <TableCell align="right">{Property}</TableCell>
                          <TableCell align="right">{account}</TableCell>
                          <TableCell align="right">{detailTotal}</TableCell>
                          <TableCell align="right">
                            {amtRemaining}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
            </div>

          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
