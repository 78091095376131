// Status Style Config
export const statusStyleConfig = {
  Initializing: "secondary",
  Reprocessing: "secondary",
  Pending: "warning",
  Approved: "info",
  "Auto Approved": "primary",
  Accepted: "success",
  Rounded: "success",
};

// Status Style Config
export const requestStatusStyleConfig = {
  Accepted: "success",
  Pending: "warning",
};

export const validationStatusConfig = {
  "Not Verified": "warning",
  Success: "primary",
  Fail: "danger",
};
