import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  MenuItem,
  FormHelperText
} from "@mui/material";
import React,{useEffect, useState} from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import MatrialuiSelect from "@mui/material/Select";
import {
  GET_EXCEPTION_HANDLER_ROLE_URL
 
} from "../../comman/url";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import {
  Name,
  getTeamID,
  getUserId,
  stableSort,
  enCryptFun,
  deCryptFun,
  getEmail,
} from "../../comman/function";
import API from "../../constants/API";
import { CustomMessage } from "../../comman/helperfunction";
import { useSnackbar } from "notistack";


const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));
let filterInitial = { name: "" ,status:""};
function ExceptionListFilter(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, closeModal, filterHandler, exceptionParams } = props;
  const [handlerRoles, setHandlerRoles] = useState([]);

  const styleclasses = useStyles();
  const [valueamt, setValueamt] = React.useState([1, 10000]);

  const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: "#EC7C37",
    height: 3,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
      height: 27,
      width: 27,
      border: "1px solid #EC7C37",
      "&:hover": {
        boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
      },
      "& .airbnb-bar": {
        height: 9,
        width: 1,
        backgroundColor: "#EC7C37",
        marginLeft: 1,
        marginRight: 1,
      },
    },
    "& .MuiSlider-track": {
      height: 3,
    },
    "& .MuiSlider-rail": {
      color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
      opacity: theme.palette.mode === "dark" ? undefined : 1,
      height: 3,
    },
  }));

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
      </SliderThumb>
    );
  }
  const getExceptionHandlerRoles = async () => {
    const config = {
      method: "GET",
      url: GET_EXCEPTION_HANDLER_ROLE_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            role: "role",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let response_decrypt = deCryptFun(response.data);
      const { data, status } = JSON.parse(response_decrypt);
      if (status == "Success") {
        setHandlerRoles(data);

      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  useEffect(()=>{
    getExceptionHandlerRoles()
  },[])
  const postData = () => {};
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: {
      ...filterInitial,
      ...exceptionParams,
    },
    //validationSchema,
    onSubmit: (values) => postToHandler(values),
  });
  const { name = "", status=""} = values;
  const postToHandler = (values) => {
    filterHandler(values);
    closeModal();
  };

  const resetField = () => {
    filterHandler(filterInitial);
    closeModal();
  };
  let beforeChange = null;

  const handleChange1 = (event, newValue) => {
    if (!beforeChange) {
      beforeChange = [...valueamt];
    }

    if (beforeChange[0] !== newValue[0] && beforeChange[1] !== newValue[1]) {
      return;
    }

    setValueamt(newValue);
  };

  const handleChangeCommitted = () => {
    beforeChange = null;
  };
  const handleChangeType = (e) => {
    handleChange(e);
   
  };
  return (
    <>
      <BootstrapDialog
        // onClose={closeModal} closed onclick outside
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeModal}
            className={styleclasses.BootstrapDialog}
            sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
          >
            Filter
          </BootstrapDialogTitle>

          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Supplier
              </Typography>
              <CssTextField
                name="name"
                value={name}
                onChange={handleChange}
                fullWidth
                id="custom-css-outlined-input"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
            <Grid item xs={12}>
              <Typography for="exceptionType">Select Exception Type</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <NativeSelect
                style={{fontFamily: "Montserrat"}}
                  type="select"
                  name="status"
                  id="status"
                  onBlur={handleBlur}
                  onChange={handleChangeType}
                  value={status}
                  input={<BootstrapInput />}
                  placeholder="select"
                >
                  <option value={""}>All</option>
                  {handlerRoles.map((role, i) => {
                    const { exceptionRoleId, exceptionRole } = role;
                    return (
                      <option key={i} value={exceptionRole}>
                        {exceptionRole == "Request Information"?"Information Requested":exceptionRole}
                      </option>
                    );
                  })}
                </NativeSelect>
                <FormHelperText>
                  {/* {errors.status && touched.status
                    ? errors.status
                    : ""} */}
                </FormHelperText>
              </FormControl>
            </Grid>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Company Name
                </Typography>
                <CssTextField
                  name="companyName"
                  value={companyName}
                  onChange={handleChange}
                  fullWidth
                  id="custom-css-outlined-input"
                />
              </Grid> */}
            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}></Grid>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Email
                </Typography>
                <CssTextField
                  name="email"
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  id="custom-css-outlined-input"
                />
              </Grid> */}
            {/* <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Amount
                </Typography>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ pt: 1, display: "flex" }}
                >
                  <Grid item xs={6} md={6} lg={6}>
                    <CssTextField fullWidth id="custom-css-outlined-input" />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    lg={1}
                    className={styleclasses.smallseperate}
                  >
                    <RemoveRoundedIcon sx={{ color: "#A0A3BD", width: "15px" }} />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <CssTextField fullWidth id="custom-css-outlined-input" />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    getAriaLabel={(index) =>
                      index === 0 ? "Minimum price" : "Maximum price"
                    }
              //       min={1}
              // max={100}
              // values={[1, 100]}
              //       defaultValue={[20, 40]}
              value={valueamt}
                  onChange={handleChange1}
                  onChangeCommitted={handleChangeCommitted}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={(index) =>
                    index === 0 ? "Minimum price" : "Maximum price"
                  }
                  />
                </Grid>
              </Grid> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button type="submit" className={styleclasses.submitdialog}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
}

export default ExceptionListFilter;
